import useThemeSettings from '@hooks/useThemeSettings';
import StyledThemeSwitch from '@layouts/main/sections/header/them-mode/styles';

export default function ThemeMode() {
  const { toggleThemeMode, themeMode } = useThemeSettings();

  function handleThemeChange(e: React.ChangeEvent<HTMLInputElement>) {
    const isThemeModeDark = e.currentTarget.checked ? 'dark' : 'light';
    toggleThemeMode(isThemeModeDark);
  }

  function switchGate() {
    return (
      <StyledThemeSwitch
        sx={{
          display: {
            xs: 'none',
            md: 'block',
          },
        }}
        checked={themeMode === 'dark'}
        onChange={handleThemeChange}
      />
    );
  }

  return switchGate();
}
