import useResponsive from '@hooks/useResponsive';
import MobileToolbar from '@layouts/main/sections/toolbar/mini/mobile';
import ToolbarProvider from '@layouts/main/sections/toolbar/providers/ToolbarProvider';
import Box from '@mui/material/Box';

const INDENTATION = <Box aria-hidden sx={{ order: 3 }} />;

export default function Toolbar({ children }: React.PropsWithChildren) {
  const isItDesktop = useResponsive('up', 'md');

  return (
    <ToolbarProvider>
      {isItDesktop ? (
        <>
          <ToolbarProvider.MiniToolbar>
            <ToolbarProvider.ExpandButton />
          </ToolbarProvider.MiniToolbar>
          <ToolbarProvider.ExpandedToolbar>
            <ToolbarProvider.ExpandButton />
          </ToolbarProvider.ExpandedToolbar>
        </>
      ) : (
        <MobileToolbar />
      )}
      <ToolbarProvider.Content>{children}</ToolbarProvider.Content>
      {isItDesktop && INDENTATION}
    </ToolbarProvider>
  );
}
