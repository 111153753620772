import { styled } from '@mui/material';
import Slider from '@mui/material/Slider';

const StyledSlider = styled(Slider)(({ theme }) => ({
  cursor: 'default',
  border: 0,
  '.MuiSlider-thumb': {
    display: 'none',
  },
  '& .MuiSlider-track': {
    background: theme.palette.gradients('to right').primary,
    border: 0,
  },
  [theme.breakpoints.up('md')]: {
    height: theme.spacing(2),
    width: '100%',
    '& .MuiSlider-track': {
      background: theme.palette.gradients('to top').primary,
    },
  },
  '& .MuiSlider-rail': {
    opacity: 1,
    backgroundColor: theme.palette.grey[100],
  },
}));

export default StyledSlider;
