import Logo from '@components/logo';
import StyledStack from '@components/user-authentication/sections/mobile/modal-stepper/styles';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { useLocation } from 'react-router-dom';

export default function ModalStepper({ children }: React.PropsWithChildren) {
  const { search } = useLocation();

  return (
    <Modal open={search.includes('secure-account')} closeAfterTransition>
      <Fade in={search.includes('secure-account')}>
        <StyledStack direction="row">
          <Stack sx={{ p: 4, width: '100%' }}>
            <Logo />
            {children}
          </Stack>
          {/* Stepper Component goes here */}
        </StyledStack>
      </Fade>
    </Modal>
  );
}
