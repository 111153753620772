import { KnowzStepDefinition } from '@lib/step/types';

const SourceTriggerStepDefinition: KnowzStepDefinition = {
  order: 105,
  group: 'Triggers',
  step: {
    componentType: 'task',
    type: 'source-trigger',
    name: 'Source'
  },
  triggerProperties: [
    {
      ref: 'sourceId',
      type: 'id-source',
    },
  ],
  propertyTypes: [
    {
      id: 'output',
      name: 'Source reference',
      description: 'Assign a name to the source for later reference it in your command',
      usage: 'output',
      wrapper: 'item',
      types: ['ref-source'],
    },
  ],
};

export default SourceTriggerStepDefinition;
