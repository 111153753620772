import { Definition } from 'sequential-workflow-designer';
import { TriggerType } from '@lib/step/types';
import { flows } from '@lib/agent';
import { queryClient } from '@providers/ReactQueryProvider';

const DEFAULT_WORKSPACE_ID = String(import.meta.env.VITE_WORKSPACE_ID);
let jwt: string | null = null; // TODO

export type RunnerResult = {
  code: number;
  output: string[];
};

export async function run(
  definition: Definition,
  triggerProperties: TriggerType[],
): Promise<RunnerResult> {
  definition.properties = {
    jwt,
    threadId: '', // TODO
    workspaceId: DEFAULT_WORKSPACE_ID,
    dataBag: triggerProperties.map((triggerProperty) => ({
      ref: triggerProperty.ref,
      type: 'number',
      data: triggerProperty.data,
    })),
  };

  const response = await queryClient.fetchQuery({
    queryKey: ['flows', definition],
    queryFn: () => flows.run({ body: definition }),
  });

  return response.data;
}

export function setJwt(newJwt: string | null) {
  jwt = newJwt;
}
