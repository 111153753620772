import { useTranslation } from '@desygner/ui-common-translation';
import { NavListItemsType } from '@layouts/main/sections/header/types';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';

type Props = {
  navListItems: NavListItemsType;
};

export default function DesktopNav({ navListItems }: Props) {
  const { t } = useTranslation();

  return (
    <Stack component={List} disablePadding direction="row">
      {navListItems.map(({ label, defaultLabel, link }, index) => {
        return (
          <ListItem key={index} disableGutters disablePadding sx={{ p: 3 }}>
            <ListItemButton
              sx={{ '&:hover': { backgroundColor: 'transparent' } }}
              href={link}
              target="_blank"
              disableGutters
              disableRipple
            >
              <ListItemText
                primaryTypographyProps={{
                  variant: 'body2',
                  color: 'text.primary',
                }}
              >
                {t(label, { defaultValue: defaultLabel })}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        );
      })}
    </Stack>
  );
}
