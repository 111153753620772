import getDataWithPagination from '@components/pages/history/utils/getDataWithPagination';
import { threads } from '@lib/agent';
import { GetAllThreadsParamsType } from '@shared-types/threads';
import { useInfiniteQuery } from '@tanstack/react-query';

const LIMIT = 10;
const ORDER = 'DESC';

export default function useGetInfiniteSearchThreads({
  type,
}: Omit<GetAllThreadsParamsType, 'limit' | 'order' | 'page'>) {
  return useInfiniteQuery({
    queryKey: ['threads', type],
    queryFn: async ({ pageParam }) => {
      const { data, headers } = await threads.getAll({
        limit: LIMIT,
        order: ORDER,
        page: pageParam,
        type,
      });

      if (data.length === 0) {
        return { data, meta: {} };
      }

      const totalCount = headers['xTotalCount'] as string;

      const dataWithPagination = getDataWithPagination({
        data,
        totalCount,
        pageParam,
        limit: LIMIT,
      });

      return dataWithPagination;
    },
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      if (lastPage.data.length === 0) return undefined;

      if (!('pagination' in lastPage.meta)) return undefined;

      if (lastPageParam === lastPage.meta.pagination.pageCount)
        return undefined;

      return lastPageParam + 1;
    },
    getPreviousPageParam: (_firstPage, _allPages, firstPageParam) => {
      if (firstPageParam <= 2) return undefined;

      return firstPageParam - 1;
    },
    initialPageParam: 1,
  });
}
