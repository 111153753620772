import { useStepEditor } from 'sequential-workflow-designer-react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import { OptionsSelector } from './OptionsSelector';
import {
  InputBlockProps,
  InputBlockWithContext,
  BagItem,
} from './InputBlockWithContext';
import { getStepPropertiesDefinition } from '../../config';
import StyledSelectedOption from './SelectedOption';
import { BagItemTypes } from '@lib/step/types';
import { styled } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import MenuItem from '@mui/material/MenuItem';

export function InputReference({
  types,
  name,
  description,
  value,
  onChange,
}: InputBlockProps) {
  const {
    id,
    definition: { sequence },
  } = useStepEditor();

  const options = [];

  for (const type of types) {
    options.push(
      ...getAvailableOptionsOfType(type, sequence as [], id)
        .map((data) => ({ data, type }))
    );
  }

  if (!options.length) {
    return (
      <Typography variant="body1">
        No {types[0]} outputs configured yet
      </Typography>
    );
  }

  const val = options.find(o => o.data === (value as BagItem).data);

  return (
    <InputBlockWithContext name={name} description={description}>
      <Select
        fullWidth
        size="small"
        value={val}
        onChange={(event) => {
          onChange && onChange({
            type: event.target.value.type,
            data: event.target.value.data
          });
        }}
      >
        {options.map((o) => (
          <MenuItem
            key={`input-${id}-${o.data}`}
            value={o}
          >{o.data}</MenuItem>
        ))}
      </Select>
    </InputBlockWithContext>
  );
}

const StyledMarginWrapper = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export function InputReferences({
  types,
  name,
  description,
  value,
  onChange,
}: InputBlockProps) {
  value = value || [];
  const {
    id,
    definition: { sequence },
  } = useStepEditor();
  const optionsByType = {};

  for (const type of types) {
    optionsByType[type] = getAvailableOptionsOfType(type, sequence as [], id);
  }

  function addValue(type: BagItemTypes) {
    (value as BagItem[]).push({ type });
    onChange && onChange(value);
  }

  function removeValue(i: number) {
    (value as BagItem[]).splice(i, 1);
    onChange && onChange(value);
  }

  return (
    <InputBlockWithContext name={name} description={description}>
      {(value as BagItem[])
        .map((v: BagItem, i: number) => {
          const val = v.data;
          const options = optionsByType[v.type];

          if (!options.length) {
            removeValue(i);
            return null;
          }

          if (options.find((o) => o === val)) {
            return (
              <StyledMarginWrapper key={`input-${id}-${i}`}>
                <StyledSelectedOption onClick={() => removeValue(i)}>
                  <Typography variant="body1">{val}</Typography>
                  <IconButton>
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </StyledSelectedOption>
              </StyledMarginWrapper>
            );
          }

          return (
            <StyledMarginWrapper key={`input-${id}-${i}`}>
              <Select
                fullWidth
                size="small"
                value={val}
                onChange={(event) => {
                  (value as BagItem[])[i].data = event.target.value;
                  onChange && onChange(value);
                }}
              >
                {options.map((o) => (
                  <MenuItem key={`input-${id}-${i}-${o}`} value={o}>{o}</MenuItem>
                ))}
              </Select>
            </StyledMarginWrapper>
          );
        })
        .filter(Boolean)}

      <OptionsSelector
        types={types
          .filter((label) => optionsByType[label] && optionsByType[label].length > 0)
          .map((label) => ({
            label,
            enabled: true,
          }))
        }
        onClick={addValue}
      />
    </InputBlockWithContext>
  );
}

function getAvailableOptionsOfType(type: string, sequence: [], id: string) {
  const opts = [];
  const currentStepIndex = `${sequence.findIndex((s) => s.id === id)}`;

  for (const i in sequence) {
    if (currentStepIndex === i) break;

    const step = sequence[i];
    const stepPropertiesDefinition = getStepPropertiesDefinition(step);
    const outputs = stepPropertiesDefinition.reduce((acc, p) => {
      if (p.usage === 'output') acc.push(p.id);
      return acc;
    }, []);

    for (const output of outputs) {
      const property = step.properties[output];

      if (property && property.data && property.type === type) {
        opts.push(property.data);
      }
    }
  }

  return opts;
}
