import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WSResponseType } from '@state/middleware/types';

type WebSocket = {
  WSResponse: WSResponseType;
  isWSConnected: boolean;
  isWSErrored: unknown;
  isWSLoading: boolean;
  WSMessageStatus: 'started' | 'finished' | null;
};

const initialState: WebSocket = {
  WSResponse: {
    assistant: {
      composed: [],
      text: '',
    },
    searchResult: {
      layout: [],
      followups: [],
      TOS: null,
    },
  },
  isWSConnected: false,
  isWSErrored: null,
  isWSLoading: false,
  WSMessageStatus: null,
};

const websocket = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    WSConnected: (state) => {
      return { ...state, isWSConnected: true };
    },
    WSDisconnected: (state) => {
      return { ...state, isWSConnected: false };
    },
    WSErrored: (state, action) => {
      return { ...state, isWSErrored: action.payload };
    },
    WSResponseUpdated: (state, action) => {
      return { ...state, WSResponse: action.payload };
    },
    WSLoaded: (state, action: PayloadAction<boolean>) => {
      return { ...state, isWSLoading: action.payload };
    },
    WSMessageStatusUpdated: (
      state,
      action: PayloadAction<'started' | 'finished'>,
    ) => {
      return { ...state, WSMessageStatus: action.payload };
    },
    WSTOSUpdated: (state, action) => {
      return {
        ...state,
        WSResponse: { ...state.WSResponse, TOS: action.payload },
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const {
  WSConnected,
  WSDisconnected,
  WSErrored,
  WSResponseUpdated,
  WSLoaded,
  WSMessageStatusUpdated,
  reset,
} = websocket.actions;

export default websocket.reducer;
