//TODO: I would like to go through this with @Nax to make sure If I have covered all edge cases. so comments can be removed afterwards.
import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import { DialogueType } from '@shared-types/sidekick';
import AssistantResultMiddleware from '@state/middleware/assistant-result';
import SearchResultMiddleware from '@state/middleware/search-result';
import socketMiddleware from '@state/middleware/websocket';
import assistantResultSlice from '@state/slices/assistant-result';
import searchResultSlice from '@state/slices/search-result';
import transcriptionSlice, {
  dialogueUpdated,
} from '@state/slices/transcription';
import websocket from '@state/slices/web-socket';
import processAsyncBothFactCheckSentimentsAndDispatchDialogueUpdated from '@state/utils/processAsyncBothFactCheckSentimentsAndDispatchDialogueUpdated';
import processAsyncFactCheckOnlyAndDispatchDialogueUpdated from '@state/utils/processAsyncFactCheckOnlyAndDispatchDialogueUpdated';
import processAsyncSentimentsOnlyAndDispatchDialogueUpdated from '@state/utils/processAsyncSentimentsOnlyAndDispatchDialogueUpdated';

const listenerMiddleware = createListenerMiddleware();

const store = configureStore({
  reducer: {
    assistantResult: assistantResultSlice,
    searchResult: searchResultSlice,
    transcription: transcriptionSlice,
    websocket: websocket,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat([
      socketMiddleware,
      AssistantResultMiddleware,
      SearchResultMiddleware,
      listenerMiddleware.middleware,
    ]);
  },
});

const unresolvedDialogueQueue: DialogueType = []; //? this is a queue of dialogues that need to be processed
const processingDialogues = new Set(); //? this is a set of dialogues that are currently being processed

listenerMiddleware.startListening.withTypes<RootType, AppDispatch>()({
  actionCreator: dialogueUpdated,
  effect: async (_, listenerApi) => {
    const { dialogue, shouldRunFactCheck, shouldRunSentimentCheck } =
      listenerApi.getState().transcription;

    const lastDialogue = dialogue[dialogue.length - 1];

    if (lastDialogue && !processingDialogues.has(lastDialogue.id)) {
      unresolvedDialogueQueue.push(lastDialogue);
    }

    while (unresolvedDialogueQueue.length > 0) {
      const currentDialogue = unresolvedDialogueQueue.shift();
      if (!currentDialogue || processingDialogues.has(currentDialogue.id))
        continue;

      processingDialogues.add(currentDialogue.id);

      if (shouldRunFactCheck && shouldRunSentimentCheck) {
        await processAsyncBothFactCheckSentimentsAndDispatchDialogueUpdated(
          currentDialogue,
          listenerApi,
        );
      }

      if (shouldRunFactCheck && !shouldRunSentimentCheck) {
        try {
          await processAsyncFactCheckOnlyAndDispatchDialogueUpdated(
            currentDialogue,
            listenerApi,
          );
        } catch (error) {
          console.error(
            'Error while processing dialogue for fact check:',
            error,
          );
        }
      }

      if (!shouldRunFactCheck && shouldRunSentimentCheck) {
        try {
          await processAsyncSentimentsOnlyAndDispatchDialogueUpdated(
            currentDialogue,
            listenerApi,
          );
        } catch (error) {
          console.error(
            'Error while processing dialogue for sentiments:',
            error,
          );
        }
      }
    }
  },
});

export type RootType = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
