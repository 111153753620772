import { useTranslation } from '@desygner/ui-common-translation';
import useAuth from '@hooks/useAuth';
import CONFIG from '@layouts/main/sections/toolbar/config';
import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';
import { StyledDesktopMiniToolbar } from '@layouts/main/sections/toolbar/mini/desktop/styles';
import { StyledListItem } from '@layouts/main/sections/toolbar/mini/styles';
import CreditUsage from '@layouts/main/sections/toolbar/mini/desktop/sections/credit-usage';
import { StyledNavLink } from '@layouts/main/sections/toolbar/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

export default function DesktopMiniToolbar({
  children,
}: React.PropsWithChildren) {
  const { t } = useTranslation();
  const {
    toolbarExpansionStatus,
    handleDisplayExpandedButton,
    handleHideExpandedButton,
  } = useToolbar();
  const { me } = useAuth();

  return (
    toolbarExpansionStatus === 'off' && (
      <StyledDesktopMiniToolbar
        onMouseEnter={handleDisplayExpandedButton}
        onMouseLeave={handleHideExpandedButton}
        disableGutters
      >
        <Box sx={{ px: CONFIG.PATH_TO_SHOW_COLLAPSING_BUTTON }}>
          <List
            disablePadding
            direction="column"
            component={Stack}
            sx={{
              justifyContent: 'space-around',
              alignItems: 'center',
              height: '100%',
              width: (theme) => ({
                xs: '100%',
                md: theme.spacing(9.5),
              }),
            }}
          >
            {CONFIG.TOOLBAR_ITEMS.filter(
              ({ isAlpha }) => me?.alpha || !isAlpha,
            ).map(({ defaultLabel, icon, mappedLabel, url }, i) => (
              <Tooltip
                key={i}
                arrow
                placement="right"
                title={t(mappedLabel, { defaultValue: defaultLabel })}
              >
                <StyledListItem
                  disablePadding
                  disableGutters
                  component={StyledNavLink}
                  to={url}
                >
                  <IconButton>{icon}</IconButton>
                </StyledListItem>
              </Tooltip>
            ))}
            <CreditUsage />
          </List>
        </Box>
        {children}
      </StyledDesktopMiniToolbar>
    )
  );
}
