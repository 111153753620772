import Tooltip from '@mui/material/Tooltip';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import StyledToolbarExtensionToggleButton from '@layouts/main/sections/toolbar/components/expand-button/styles';
import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';
import { useTranslation } from '@desygner/ui-common-translation';

export default function ExpandButton() {
  const {
    handleToggleToolbar,
    toolbarExpansionStatus,
    isExpandedButtonDisplayed,
  } = useToolbar();
  const { t } = useTranslation();
  const isToolbarExpanded = toolbarExpansionStatus === 'on';

  return (
    isExpandedButtonDisplayed && (
      <Tooltip
        arrow
        placement="right"
        title={
          isToolbarExpanded
            ? t('layout.toolbar.buttons.collapse')
            : t('layout.toolbar.buttons.expand')
        }
      >
        <StyledToolbarExtensionToggleButton onClick={handleToggleToolbar}>
          {isToolbarExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </StyledToolbarExtensionToggleButton>
      </Tooltip>
    )
  );
}
