import {
  KnowzIcon,
  OfficeWordIcon,
  PdfIcon,
  SlackIcon,
} from '@components/pages/knowledge-base/components/table/MockIcons';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';

export type Columns = {
  id: number;
  name: {
    label: string;
    icon?: JSX.Element;
  };
  format: {
    label: string;
    icon?: JSX.Element;
  };
  location: {
    label: string;
    icon?: JSX.Element;
  };
  status: 'in_progress' | 'synced' | 'error';
  permission: Array<{
    name: string;
    avatar: string;
  }>;
  action?: string;
};

export function createMockData({
  id,
  name,
  format,
  location,
  permission,
  status,
}: Columns) {
  return {
    id,
    name,
    format,
    location,
    status,
    permission,
  };
}

export const ROWS = [
  createMockData({
    id: 1,
    name: {
      label: 'Folder 1',
      icon: <FolderOutlinedIcon />,
    },
    format: {
      label: 'pdf',
      icon: <PdfIcon />,
    },
    location: {
      label: 'knowz',
      icon: <KnowzIcon />,
    },
    permission: [
      {
        avatar: 'https://avatar.iran.liara.run/public/6',
        name: 'Tom',
      },
      {
        avatar: 'https://avatar.iran.liara.run/public/7',
        name: 'Nax',
      },
    ],
    status: 'in_progress',
  }),
  createMockData({
    id: 2,
    name: {
      label: 'Folder 2',
      icon: <FolderOutlinedIcon />,
    },
    format: {
      label: 'Word Office',
      icon: <OfficeWordIcon />,
    },
    location: {
      label: 'knowz',
      icon: <KnowzIcon />,
    },
    permission: [
      {
        avatar: 'https://avatar.iran.liara.run/public/1',
        name: 'Amir',
      },
      {
        avatar: 'https://avatar.iran.liara.run/public/2',
        name: 'Victor',
      },
    ],
    status: 'in_progress',
  }),
  createMockData({
    id: 3,
    name: {
      label: 'Folder 3',
      icon: <FolderOutlinedIcon />,
    },
    format: {
      label: 'Word Office',
      icon: <OfficeWordIcon />,
    },
    location: {
      label: 'slack',
      icon: <SlackIcon />,
    },
    permission: [
      {
        avatar: 'https://avatar.iran.liara.run/public/3',
        name: 'Tom',
      },
      {
        avatar: 'https://avatar.iran.liara.run/public/4',
        name: 'Amir',
      },
    ],
    status: 'in_progress',
  }),
  createMockData({
    id: 4,
    name: {
      label: 'Folder 4',
      icon: <FolderOutlinedIcon />,
    },
    format: {
      label: 'pdf',
      icon: <PdfIcon />,
    },
    location: {
      label: 'slack',
      icon: <SlackIcon />,
    },
    permission: [
      {
        avatar: 'https://avatar.iran.liara.run/public/5',
        name: 'Pablo',
      },
      {
        avatar: 'https://avatar.iran.liara.run/public/6',
        name: 'Amir',
      },
    ],
    status: 'synced',
  }),
  createMockData({
    id: 4,
    name: {
      label: 'Folder 5',
      icon: <FolderOutlinedIcon />,
    },
    format: {
      label: 'pdf',
      icon: <PdfIcon />,
    },
    location: {
      label: 'slack',
      icon: <SlackIcon />,
    },
    permission: [
      {
        avatar: 'https://avatar.iran.liara.run/public/7',
        name: 'Pablo',
      },
      {
        avatar: 'https://avatar.iran.liara.run/public/8',
        name: 'Nax',
      },
    ],
    status: 'synced',
  }),
  createMockData({
    id: 5,
    name: {
      label: 'file 1',
    },
    format: {
      label: 'pdf',
      icon: <PdfIcon />,
    },
    location: {
      label: 'knowz',
      icon: <KnowzIcon />,
    },
    permission: [
      {
        avatar: 'https://avatar.iran.liara.run/public/9',
        name: 'Joey',
      },
      {
        avatar: 'https://avatar.iran.liara.run/public/10',
        name: 'Oscar',
      },
    ],
    status: 'error',
  }),
  createMockData({
    id: 6,
    name: {
      label: 'file 2',
    },
    format: {
      label: 'pdf',
      icon: <PdfIcon />,
    },
    location: {
      label: 'Slack',
      icon: <SlackIcon />,
    },
    permission: [
      {
        avatar: 'https://avatar.iran.liara.run/public/9',
        name: 'Nax',
      },
      {
        avatar: 'https://avatar.iran.liara.run/public/10',
        name: 'Nax',
      },
    ],
    status: 'error',
  }),
];
