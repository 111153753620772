import { styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';

const StyledToolbarExtensionToggleButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  left: '100%',
  top: '50%',
  transform: 'translate(-50%)',
  backgroundColor: theme.palette.background.secondary,
  border: '1px solid',
  borderColor: theme.palette.background.secondary,
  padding: theme.spacing(1),
  marginLeft: theme.spacing(7),
}));

export default StyledToolbarExtensionToggleButton;
