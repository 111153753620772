import { KnowzStepDefinition } from '../types';

const IterateStepDefinition: KnowzStepDefinition = {
  order: 301,
  group: 'Logic',
  step: {
    componentType: 'container',
    type: 'reference-iterator',
    name: 'Iterate References',
    sequence: [],
  },
  propertyTypes: [
    {
      id: 'input',
      name: 'Content',
      description: 'Select the content you want to iterate over',
      usage: 'input',
      wrapper: 'list',
      types: ['ref-strings', 'ref-numbers', 'ref-matches'],
    },
    {
      id: 'output',
      name: 'Item reference',
      description: 'Name the current item in the iteration for later reference in your command',
      usage: 'output',
      wrapper: 'item',
      types: ['ref-string', 'ref-number', 'ref-match'],
    },
  ],
};

export default IterateStepDefinition;
