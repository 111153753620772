import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const StyledLogo = styled('img')(({ theme }) => ({
  filter: theme.palette.mode === 'light' ? 'invert(1)' : 'invert(0)',
}));
