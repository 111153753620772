import { useTranslation } from '@desygner/ui-common-translation';
import useSearchParamsState from '@hooks/useSearchParamsState';
import Button, { ButtonProps } from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import useAuth from '@hooks/useAuth';
import { signUp } from '@lib/auth/recaptcha';
import { toast } from 'react-toastify';
import useUserAuth from '@components/user-authentication/hooks/useUserAuth';

type TrimmedButtonProps = Omit<ButtonProps, 'label'>;

const MOBILE_BUTTONS: Array<
  TrimmedButtonProps & {
    mappedLabel: 'page.auth.mobile.buttons.primary';
    defaultLabel: 'Get started';
  }
> = [
  {
    mappedLabel: 'page.auth.mobile.buttons.primary',
    defaultLabel: 'Get started',
    variant: 'contained',
  },
];

export default function AuthButton() {
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParamsState('action', '');
  const { me } = useAuth();
  const { resetForm } = useUserAuth();

  function openModal() {
    setSearchParams('secure-account');
  }

  //TODO: we have some repetitive logic for both mobile/desktop dry it up later!
  async function handleLogout() {
    window.localStorage.removeItem('token');
    resetForm();
    toast.success(
      t('response.success.logout', {
        defaultValue: 'You have successfully logged out',
      }),
    );

    const recaptchaToken = window.localStorage.getItem('_grecaptcha');
    if (recaptchaToken) {
      await signUp(recaptchaToken);
    } else {
      window.location.reload();
    }
  }

  if (me && me.auth_type === 'email') {
    return (
      <Button
        onClick={handleLogout}
        variant="outlined"
        endIcon={<LogoutIcon />}
      >
        {t('layout.auth.logout', {
          defaultValue: 'Logout',
        })}
      </Button>
    );
  }

  return MOBILE_BUTTONS.map(({ defaultLabel, mappedLabel, ...other }) => {
    return (
      <Button key={mappedLabel} size="small" {...other} onClick={openModal}>
        {t(mappedLabel, { defaultValue: defaultLabel })}
      </Button>
    );
  });
}
