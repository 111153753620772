import FollowupsBlock from '@components/pages/search/components/shared-blocks/followups';
import { WSResponseType } from '@state/middleware/types';
import { RootType } from '@state/store';
import { useSelector } from 'react-redux';

export default function Followups() {
  const WSResponse = useSelector<RootType, WSResponseType>(
    (state) => state.websocket.WSResponse,
  );
  const isWSLoading = useSelector<RootType, boolean>(
    (state) => state.websocket.isWSLoading,
  );
  const WSMessageStatus = useSelector<RootType, 'started' | 'finished' | null>(
    (state) => state.websocket.WSMessageStatus,
  );

  return (
    WSResponse.searchResult.followups.length > 0 && (
      <FollowupsBlock
        followups={WSResponse.searchResult.followups}
        disabled={isWSLoading || WSMessageStatus === 'started'}
      />
    )
  );
}
