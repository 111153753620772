import useGetCreditAsPercentage from '@hooks/useGetCreditAsPercentage';
import StyledSlider from '@layouts/main/sections/toolbar/expanded/sections/credit-usage/styles';
import PurchasedAICredit from '@layouts/main/sections/toolbar/expanded/sections/purchased-ai-credit';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

const STAR_SIZE = 20;

export default function CreditUsage() {
  const { creditsData, isCreditLoading } = useGetCreditAsPercentage();

  return (
    <Box
      sx={{
        backgroundColor: 'background.secondary',
        borderRadius: 3,
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <Stack
        direction="row"
        gap={2}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          p: 3,
        }}
      >
        <img
          src="https://assets.knowz.com/assets/images/star.png"
          alt="star"
          width={STAR_SIZE}
          height={STAR_SIZE}
        />
        <StyledSlider value={creditsData || 0} orientation="horizontal" />
        {isCreditLoading ? (
          <Skeleton variant="rectangular" width={40} height={21} />
        ) : (
          <Typography variant="overline" sx={{ fontWeight: 400 }}>
            {creditsData! < 0 ? 0 : Math.round(Number(creditsData))}%
          </Typography>
        )}
      </Stack>
      <PurchasedAICredit />
    </Box>
  );
}
