import { alpha, styled } from '@mui/material';
import Switch from '@mui/material/Switch';

const StyledThemeSwitch = styled(Switch)(({ theme }) => {
  const TRACK_WIDTH = 67;
  const TRACK_HEIGHT = 32;
  const THUMB_SIZE = 26;
  const THUMB_BG_COLOR = theme.palette.common.white;
  //TODO: Icons should be exactly the same that are used in figma
  const MOON = (color: string) =>
    `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
      `${color}`,
    )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`;
  const SUN = (color: string) =>
    `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
      `${color}`,
    )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`;

  return {
    width: TRACK_WIDTH,
    height: TRACK_HEIGHT,
    padding: 0,
    margin: 0,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? alpha(theme.palette.common.white, 0.06)
        : alpha(theme.palette.warning.dark, 0.06),
    borderRadius: 100,
    '& .MuiSwitch-input': {
      width: '200%',
    },
    '&::before, &::after': {
      content: "''",
      position: 'absolute',
      width: 18,
      height: 18,
      top: '50%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      transform: 'translateY(-50%)',
    },
    '&::after': {
      left: 5,
      backgroundImage: SUN(theme.palette.text.primary),
    },
    '&::before': {
      right: 5,
      backgroundImage: MOON(theme.palette.text.primary),
    },
    '& .MuiSwitch-switchBase': {
      padding: 3,
      '&.Mui-checked': {
        transform: 'translateX(35px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: MOON(theme.palette.neutral.black),
        },
        '& + .MuiSwitch-track': {
          backgroundColor: 'transparent',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: THUMB_BG_COLOR,
      width: THUMB_SIZE,
      height: THUMB_SIZE,
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: SUN(theme.palette.warning.main),
      },
    },
    '& .MuiSwitch-track': {
      height: TRACK_HEIGHT,
      width: TRACK_WIDTH,
      backgroundColor: 'transparent',
      position: 'relative',
      opacity: 1,
    },
  };
});

export default StyledThemeSwitch;
