import CommandDesigner from '@components/pages/command-designer';
import CanvasStars from '@components/canvas-stars';
import { StyledAnimatedBox } from '@components/pages/styles';
import FlowRunProvider from '@providers/FlowRunContext';

export default function CommandDesignerPage() {
  return (
    <FlowRunProvider>
      <StyledAnimatedBox aria-hidden />
      {/* <CanvasStars /> */}
      <CommandDesigner />
    </FlowRunProvider>
  );
}
