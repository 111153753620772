import { KnowzStepDefinition } from '../../types';

const SendEmailStepDefinition: KnowzStepDefinition = {
  order: 403,
  group: 'Knowz Cloud',
  step: {
    componentType: 'task',
    type: 'send-email',
    name: 'Send Email'
  },
  propertyTypes: [
    {
      id: 'inputs',
      name: 'Email context',
      description: 'Define the different inputs for the email',
      usage: 'input',
      wrapper: 'list',
      types: ['ref-string', 'ref-source', 'ref-thread', 'ref-history-block'],
    },
    {
      id: 'subject',
      name: 'Smart subject',
      description: 'Define the subject of the email. You can inline the inputs you defined',
      usage: 'definition',
      wrapper: 'item',
      types: ['string'],
    },
    {
      id: 'body',
      name: 'Smart body',
      description: 'Define the body of the email. You can inline the inputs you defined',
      usage: 'definition',
      wrapper: 'item',
      types: ['string'],
    },
  ],
};

export default SendEmailStepDefinition;
