import {
  StyledCenteredContent,
  StyledViewportHeightStack,
} from '@components/pages/styles';
import Container from '@mui/material/Container';
import { ConfirmProvider } from 'material-ui-confirm';
import Commands from '@components/pages/commands/Commands';

export default function CommandsIndex() {
  return (
    <ConfirmProvider
      defaultOptions={{
        confirmationButtonProps: {
          variant: 'contained',
        },
        cancellationButtonProps: {
          variant: 'outlined',
        },
        // allowClose: false,
      }}
    >
      <Container>
        <StyledViewportHeightStack>
          <StyledCenteredContent gap={8}>
            <Commands />
          </StyledCenteredContent>
        </StyledViewportHeightStack>
      </Container>
    </ConfirmProvider>
  );
}
