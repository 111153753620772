import { createContext, useEffect, useReducer } from 'react';
import { io } from 'socket.io-client';

const supportedMessages = ['flow_start', 'flow_step', 'flow_end'];
const socket = io(import.meta.env.VITE_KNOWZ_WEB_SOCKET_URL);

export const FlowRunContext = createContext<{
  flow: any,
  error: string|null,
  runnerStatus: 'idle'|'running'|'done',
  socketStatus: 'connecting'|'connected'|'disconnected';
} | null>(null);

const initialState: any = {
  flow: null,
  error: null,
  runnerStatus: 'idle',
  socketStatus: 'disconnected',
};

const reducer = (state: any, action: any) => {
  const keys = Object.keys(state);

  if (keys.includes(action.type)) {
    return { ...state, ...action.payload };
  }

  return state;
};

export default function FlowRunProvider({
  children,
}: React.PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    socket.on('message', handleWebSocketResponse);
    socket.on('error', handleError);
    socket.on('disconnect', handleError);
    socket.emit('subscribe', `thread_dummy`);

    dispatch({ type: 'runnerStatus', payload: { runnerStatus: 'idle' } });
    dispatch({ type: 'socketStatus', payload: { socketStatus: 'connecting' } });

    function handleWebSocketResponse(response: any) {
      dispatch({
        type: 'socketStatus',
        payload: {
          socketStatus: socket.connected
            ? 'connected'
            : 'disconnected',
        },
      });

      if (supportedMessages.includes(response?.type)) {
        dispatch({ type: 'error', payload: { flow: response } });

        if (response.type === 'flow_start') {
          dispatch({ type: 'runnerStatus', payload: { runnerStatus: 'running' } });
        } else if (response.type === 'flow_end') {
          dispatch({ type: 'runnerStatus', payload: { runnerStatus: 'done' } });
        }
      }
    }

    function handleError(error: any) {
      dispatch({ type: 'socketStatus', payload: { socketStatus: 'disconnected' } });
      dispatch({ type: 'error', payload: { error } });
    }

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('message');
      socket.off('error');
    };
  }, []);

  return (
    <FlowRunContext.Provider value={{ ...state }}>
      {children}
    </FlowRunContext.Provider>
  );
}
