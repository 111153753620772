//TODO: useScrollIntoView hook does not work well for the data that has not been loaded yet. refactor needed.
import { DependencyList, useEffect } from 'react';

export default function useScrollIntoView(
  ref: React.RefObject<HTMLElement>,
  deps: Partial<DependencyList> = [],
) {
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
