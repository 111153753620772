import ErrorTemplate from '@components/pages/error/sections/error-template';
import { useRouteError } from 'react-router-dom';

const WIDTH = 504;
const HEIGHT = 264;

export default function Error() {
  const error = useRouteError();

  console.error(error);

  if (error?.status === 404) {
    return (
      <ErrorTemplate
        img={
          <img
            height={HEIGHT}
            width={WIDTH}
            src={'https://assets.knowz.com/assets/svg/404.svg'}
            alt="page not found"
          />
        }
      />
    );
  }

  return (
    <ErrorTemplate
      img={
        <img
          height={HEIGHT}
          width={WIDTH}
          src={'https://assets.knowz.com/assets/svg/500.svg'}
          alt="server side error"
        />
      }
    />
  );
}
