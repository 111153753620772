import { styled } from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {
  BagItem,
  InputBlockProps,
  InputBlockWithContext,
} from './InputBlockWithContext';
import { OptionsSelector } from './OptionsSelector';
import Textarea from '@components/textarea';
import {
  Source,
  Template,
} from '@components/pages/command-designer/entity-selectors';

export default function SingleObject(props: InputBlockProps) {
  const { types } = props;

  return types.length === 1 ? (
    <SingleTypeObject {...props} />
  ) : (
    <MultiTypeObject {...props} />
  );
}

function SingleTypeObject({
  types,
  options,
  name,
  description,
  value,
  variant,
  onChange,
}: InputBlockProps) {
  return (
    <InputBlockWithContext name={name} description={description}>
      <ObjectBlock
        options={options}
        variant={variant}
        bagItem={value || { type: types[0] }}
        onChange={onChange}
      />
    </InputBlockWithContext>
  );
}

function MultiTypeObject({
  types,
  name,
  description,
  value,
  variant,
  onChange,
}: InputBlockProps) {
  function addValue(type: string) {
    onChange({ type, value: null });
  }

  return (
    <InputBlockWithContext name={name} description={description}>
      {value && (
        <ObjectBlock variant={variant} bagItem={value} onChange={onChange} />
      )}
      <OptionsSelector types={types} onClick={addValue} />
    </InputBlockWithContext>
  );
}

interface ObjectBlockProps {
  variant?: string;
  bagItem: BagItem;
  options?: Array<string>;
}

const StyledTextArea = styled('div')`
  textarea,
  textarea:hover,
  textarea:focus {
    border-color: ${({ theme }) => theme.palette.background.card.main};
  }
`;

function ObjectBlock(props: ObjectBlockProps) {
  const {
    bagItem: { type, data },
    options,
    variant,
    ...rest
  } = props;

  switch (type) {
    case 'id-source':
      return (
        <Source
          value={(data || null) as number | null}
          onChange={(source) => rest.onChange({ type, data: source?.id })}
        ></Source>
      );

    case 'id-template':
      return (
        <Template
          value={(data || null) as number | null}
          onChange={(template) => rest.onChange({ type, data: template?.id })}
        ></Template>
      );

    case 'id-history-block':
      return (
        <TextField
          fullWidth
          size="small"
          value={data}
          placeholder="Enter history block id"
          onChange={(el) => rest.onChange({ type, data: el.target.value })}
        />
      );

    case 'id-thread':
      return (
        <TextField
          fullWidth
          size="small"
          value={data}
          placeholder="Enter thread id"
          onChange={(el) => rest.onChange({ type, data: el.target.value })}
        />
      );

    case 'string':
      if (options) {
        return (
          <Select
            fullWidth
            onChange={(data) => {
              rest.onChange({ type, data });
            }}
          >
            {options.map((o) => (
              <MenuItem value={o} selected={o === data}>
                {o}
              </MenuItem>
            ))}
          </Select>
        );
      }

      if (variant === 'short') {
        return (
          <TextField
            fullWidth
            size="small"
            value={data}
            onChange={(el) => rest.onChange({ type, data: el.target.value })}
          />
        );
      }

      return (
        <StyledTextArea>
          <Textarea
            minRows={3}
            value={data}
            onChange={(el) => rest.onChange({ type, data: el.target.value })}
          />
        </StyledTextArea>
      );

    // case 'number':
    //   return <input type="number" value={data} {...rest} />;

    default:
      throw new Error(`Unsupported type: ${type}`);
  }
}
