import { useTranslation } from '@desygner/ui-common-translation';
import { commands, storage } from '@lib/agent';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import useAuth from '@hooks/useAuth';
import { isAxiosError } from 'axios';
import { UpdateOneCommandParamsType, CommandMinType } from '@shared-types/agent';
import { queryClient } from '@providers/ReactQueryProvider';

const MAX_COMMAND_NAME_LENGTH = 60;
const DOTS_LENGTH = 3;

export default useCreateOneCommand;

export function useCreateOneCommand() {
  const { t } = useTranslation();
  return useMutation({
    mutationFn: createOneCommand,
    mutationKey: ['createOneCommand'],
    onError: (error) => onError(t, error),
    onSuccess: (data, id) => {
      const commandsCache = queryClient.getQueryData(['commands']) as { data: CommandMinType[] };
      if (commandsCache && commandsCache.data) {
        const { data: commandsData } = commandsCache;
        commandsData.push(data.data);
        queryClient.setQueryData(['commands'], commandsCache);
      }
    },
  });
}

export function useDeleteOneCommand() {
  const { t } = useTranslation();
  return useMutation({
    mutationFn: commands.deleteOneCommand,
    onError: (error) => onError(t, error),
    onSuccess: (data, id) => {
      const commandsCache = queryClient.getQueryData(['commands']) as { data: CommandMinType[] };
      if (commandsCache && commandsCache.data) {
        const { data: commandsData } = commandsCache;
        const commandInCacheIndex = commandsData.findIndex((command) => command.id === id);
        commandInCacheIndex !== -1 && commandsData.splice(commandInCacheIndex, 1);
        queryClient.setQueryData(['commands'], commandsCache);
      }

      queryClient.setQueryData(['command', id], data);
    },
  });
}

export function useUpdateOneCommand() {
  const { t } = useTranslation();
  return useMutation({
    mutationFn: updateOneCommand,
    onError: (error) => onError(t, error),
    onSuccess: (data, variables) => {
      const commandsCache = queryClient.getQueryData(['commands']) as { data: CommandMinType[] };
      // Review this if we move to different serialization between CommandType and CommandMinType
      if (commandsCache && commandsCache.data) {
        const { data: commandsData } = commandsCache;
        const commandInCache = commandsData.find((command) => command.id === variables.id);

        if (commandInCache) {
          const updatedCommand = data.data as CommandMinType;

          for (const key in updatedCommand) {
            commandInCache[key] = updatedCommand[key];
          }
        }
        queryClient.setQueryData(['commands'], commandsCache);
      }

      queryClient.setQueryData(['command', variables.id], data);
    },
  });
}

export function useGetAllCommands() {
  const { isUserAuthenticated } = useAuth();
  const { t } = useTranslation();
  const { data: commandsData, isLoading: isLoadingCommands } = useQuery({
    queryKey: ['commands'],
    queryFn: async () => {
      try {
        return await commands.getAllCommands();
      } catch (error) {
        onError(t, error);
      }
    },
    enabled: isUserAuthenticated,
  });

  return { commandsData, isLoadingCommands };
}

export function useGetCommand(commandId: number|string) {
  const { isUserAuthenticated } = useAuth();
  const { t } = useTranslation();
  const { data: commandData, isLoading: isLoadingCommand } = useQuery({
    queryKey: ['command', commandId],
    queryFn: async () => {
      try {
        return await commands.getCommand(commandId);
      } catch (error) {
        onError(t, error);
      }
    },
    enabled: isUserAuthenticated,
  });

  return { commandData, isLoadingCommand };
}

export function useGetCommandDefinition(commandId: number|string) {
  const { isUserAuthenticated } = useAuth();
  const { t } = useTranslation();
  const { data: commandDefinition, isLoading: isLoadingCommandDefinition } = useQuery({
    queryKey: ['commandDefinition', commandId],
    queryFn: async () => {
      try {
        const s3Key = `commands/${commandId}/definition.json`;
        const { data: credentials } = await storage.getCredentials(s3Key);

        try {
          const { data } = await commands.getCommandDefinition(credentials.url);
          return data;
        } catch (error) {
          if (isAxiosError(error) && error.response?.status === 404) {
            return {
              properties: {},
              sequence: [],
            };
          } else {
            throw error;
          }
        }
      } catch (error) {
        onError(t, error);
      }
    },
    enabled: isUserAuthenticated,
  });

  return { commandDefinition, isLoadingCommandDefinition };
}

export function useSaveCommandDefinition() {
  const { t } = useTranslation();
  return useMutation({
    mutationFn: saveCommandDefinition,
    onError: (error) => onError(t, error),
    onSuccess: (_, { commandId, definition }) => {
      queryClient.setQueryData(['commandDefinition', commandId], definition)
    },
  });
}

function onError(t, error: any) {
  toast.error(
    t('response.errors.code.520', {
      defaultValue: 'Unknown error. Please try again later.',
    }),
  );
  console.error(error);
}

type SaveCommandDefinitionParamsType = {
  commandId: number|string;
  definition: {
    properties: any;
    sequence: any[];
  }
};

async function saveCommandDefinition({ commandId, definition }: SaveCommandDefinitionParamsType) {
  const body = JSON.stringify(definition);
  const size = new Blob([body]).size;
  const { data } = await storage.getPolicy({
    type: 'application/json',
    key: 'definitions.json',
    size: size,
    context: {
      'entity_type': 'command',
      'entity_id': commandId
    }
  });

  return await storage.uploadFile({
    url: data.url,
    body,
    contentType: 'application/json',
  });
}

async function updateOneCommand(payload: UpdateOneCommandParamsType) {
  const { id, ...rest } = payload;

  if (rest.name && rest.name.length > MAX_COMMAND_NAME_LENGTH) {
    rest.name = rest.name.slice(0, MAX_COMMAND_NAME_LENGTH - DOTS_LENGTH) + '...';
  }

  return await commands.updateOneCommand(id, rest);
}

type CreateOneCommandParamsType = {
  name: string;
  icon: string;
  description: string;
};

async function createOneCommand({
  name,
  description,
  icon
}: CreateOneCommandParamsType) {
  let truncatedName = undefined as string | undefined;

  if (name.length > MAX_COMMAND_NAME_LENGTH) {
    truncatedName = name.slice(0, MAX_COMMAND_NAME_LENGTH - DOTS_LENGTH) + '...';
  }

  return await commands.createOneCommand({
    name: truncatedName || name,
    description,
    icon,
  });
}
