import AnswerBlock from '@components/pages/search/components/shared-blocks/answer';
import { WSResponseType } from '@state/middleware/types';
import { RootType } from '@state/store';
import { useSelector } from 'react-redux';

export default function StreamingAnswer() {
  const WSResponse = useSelector<RootType, WSResponseType>(
    (state) => state.websocket.WSResponse,
  );

  const answer = WSResponse.searchResult.layout.find(
    (block) => block?.type === 'answer',
  );

  return answer && <AnswerBlock answer={answer.text} />;
}
