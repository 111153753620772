import { KnowzStepDefinition } from '@lib/step/types';

const AssistStepDefinition: KnowzStepDefinition = {
  order: 203,
  group: 'AI',
  step: {
    componentType: 'task',
    type: 'assist',
    name: 'Assist'
  },
  propertyTypes: [
    {
      id: 'inputs',
      name: 'Summarization context',
      description: 'Define the different inputs for the summarization',
      usage: 'input',
      wrapper: 'list',
      types: ['ref-string', 'ref-source', 'ref-thread', 'ref-history-block'],
    },
    {
      id: 'prompt',
      name: 'Smart prompt',
      description:
        'Describe the task to the AI assistant, inlining the inputs you defined',
      usage: 'definition',
      wrapper: 'item',
      types: ['string'],
    },
    {
      id: 'output',
      name: 'Result reference',
      description:
        'Assign a name to the resulting text, for later referencing in your command',
      usage: 'output',
      wrapper: 'item',
      types: ['ref-string'],
    },
  ],
};

export default AssistStepDefinition;
