export default {
  custom: {
    color: '#FFFFFF',
    icon: 'https://weeverapps.com/wp-content/uploads/2022/04/SSO-Badge.png',
  },

  yahoo: {
    color: '#6001D2',
    icon: 'https://cdn-icons-png.flaticon.com/512/174/174848.png',
  },

  yandex: {
    color: '#FF0000',
    icon: 'https://cdn-icons-png.flaticon.com/512/353/353651.png',
  },

  trello: {
    color: '#0079BF',
    icon: 'https://cdn-icons-png.flaticon.com/512/174/174879.png',
  },

  office365: {
    color: '#D83B01',
    icon: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
  },

  dropbox: {
    color: '#0061FF',
    icon: 'https://cdn-icons-png.flaticon.com/512/174/174879.png',
  },

  reddit: {
    color: '#FF4500',
    icon: 'https://cdn-icons-png.flaticon.com/512/174/174866.png',
  },

  jawbone: {
    color: '#000000',
    icon: 'https://cdn-icons-png.flaticon.com/512/2917/2917223.png',
  },

  hubic: {
    color: '#00A2ED',
    icon: 'https://hubic.com/favicon.ico',
  },

  flickr: {
    color: '#FF0084',
    icon: 'https://cdn-icons-png.flaticon.com/512/174/174872.png',
  },

  paypal: {
    color: '#003087',
    icon: 'https://cdn-icons-png.flaticon.com/512/174/174861.png',
  },

  asana: {
    color: '#E57373',
    icon: 'https://cdn-icons-png.flaticon.com/512/174/174855.png',
  },

  mailRu: {
    color: '#005FF9',
    icon: 'https://cdn-icons-png.flaticon.com/512/888/888839.png',
  },

  twitter: {
    color: '#1DA1F2',
    icon: 'https://cdn-icons-png.flaticon.com/512/733/733579.png',
  },

  bitbucket: {
    color: '#205081',
    icon: 'https://cdn-icons-png.flaticon.com/512/5968/5968293.png',
  },

  runKeeper: {
    color: '#00B5E2',
    icon: 'https://runkeeper.com/favicon.ico',
  },

  youtube: {
    color: '#FF0000',
    icon: 'https://cdn-icons-png.flaticon.com/512/1384/1384060.png',
  },

  stereomood: {
    color: '#F8C83E',
    icon: 'https://stereomood.com/favicon.ico',
  },

  gitHub: {
    color: '#181717',
    icon: 'https://cdn-icons-png.flaticon.com/512/25/25231.png',
  },

  amazon: {
    color: '#FF9900',
    icon: 'https://cdn-icons-png.flaticon.com/512/732/732076.png',
  },

  sinaWeibo: {
    color: '#E6162D',
    icon: 'https://cdn-icons-png.flaticon.com/512/174/174876.png',
  },

  twitch: {
    color: '#9146FF',
    icon: 'https://cdn-icons-png.flaticon.com/512/1384/1384062.png',
  },

  bitly: {
    color: '#EE6123',
    icon: 'https://cdn-icons-png.flaticon.com/512/581/581674.png',
  },

  discogs: {
    color: '#000000',
    icon: 'https://cdn-icons-png.flaticon.com/512/1532/1532382.png',
  },

  sensioConnect: {
    color: '#000000',
    icon: 'https://sensiolabs.com/favicon.ico',
  },

  telegram: {
    color: '#0088CC',
    icon: 'https://cdn-icons-png.flaticon.com/512/2111/2111646.png',
  },

  deezer: {
    color: '#000000',
    icon: 'https://cdn-icons-png.flaticon.com/512/888/888854.png',
  },

  wordpress: {
    color: '#21759B',
    icon: 'https://cdn-icons-png.flaticon.com/512/174/174881.png',
  },

  google: {
    color: '#4285F4',
    icon: 'https://cdn-icons-png.flaticon.com/512/281/281764.png',
  },

  odnoklassniki: {
    color: '#EE8208',
    icon: 'https://cdn-icons-png.flaticon.com/512/1400/1400827.png',
  },

  qQ: {
    color: '#EB1923',
    icon: 'https://cdn-icons-png.flaticon.com/512/3128/3128269.png',
  },

  bufferApp: {
    color: '#168EEA',
    icon: 'https://cdn-icons-png.flaticon.com/512/874/874145.png',
  },

  linkedin: {
    color: '#0A66C2',
    icon: 'https://cdn-icons-png.flaticon.com/512/174/174857.png',
  },

  spotify: {
    color: '#1DB954',
    icon: 'https://cdn-icons-png.flaticon.com/512/174/174872.png',
  },

  gitLab: {
    color: '#FC6D26',
    icon: 'https://cdn-icons-png.flaticon.com/512/5968/5968869.png',
  },

  trakt: {
    color: '#ED1C24',
    icon: 'https://trakt.tv/favicon.ico',
  },

  deviantart: {
    color: '#05CC47',
    icon: 'https://cdn-icons-png.flaticon.com/512/217/217024.png',
  },

  itembase: {
    color: '#003300',
    icon: 'https://itembase.com/favicon.ico',
  },

  disqus: {
    color: '#2E9FFF',
    icon: 'https://cdn-icons-png.flaticon.com/512/174/174860.png',
  },

  dailymotion: {
    color: '#0066DC',
    icon: 'https://cdn-icons-png.flaticon.com/512/888/888874.png',
  },

  facebook: {
    color: '#1877F2',
    icon: 'https://cdn-icons-png.flaticon.com/512/174/174848.png',
  },

  bitbucket2: {
    color: '#205081',
    icon: 'https://cdn-icons-png.flaticon.com/512/5968/5968293.png',
  },

  slack: {
    color: '#611F69',
    icon: 'https://cdn-icons-png.flaticon.com/512/174/174883.png',
  },

  soundcloud: {
    color: '#FF8800',
    icon: 'https://cdn-icons-png.flaticon.com/512/145/145809.png',
  },

  instagram: {
    color: '#E4405F',
    icon: 'https://cdn-icons-png.flaticon.com/512/174/174855.png',
  },

  box: {
    color: '#0070E0',
    icon: 'https://cdn-icons-png.flaticon.com/512/174/174861.png',
  },

  eveOnline: {
    color: '#000000',
    icon: 'https://www.eveonline.com/favicon.ico',
  },

  apple: {
    color: '#000000',
    icon: 'https://cdn-icons-png.f',
  },

  windowsLive: {
    color: '#00BCF2',
    icon: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
  },

  genius: {
    color: '#FFFC00',
    icon: 'https://cdn-icons-png.flaticon.com/512/888/888874.png',
  },

  salesforce: {
    color: '#00A1E0',
    icon: 'https://cdn-icons-png.flaticon.com/512/888/888874.png',
  },

  vkontakte: {
    color: '#4C75A3',
    icon: 'https://cdn-icons-png.flaticon.com/512/1409/1409945.png',
  },

  stackExchange: {
    color: '#1E5397',
    icon: 'https://cdn-icons-png.flaticon.com/512/2111/2111647.png',
  },

  azure: {
    color: '#0089D6',
    icon: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
  },

  fiware: {
    color: '#40929C',
    icon: 'https://www.fiware.org/favicon.ico',
  },

  strava: {
    color: '#FC4C02',
    icon: 'https://cdn-icons-png.flaticon.com/512/888/888874.png',
  },

  clever: {
    color: '#2A76F4',
    icon: 'https://cdn-icons-png.flaticon.com/512/888/888874.png',
  },

  keycloak: {
    color: '#0066CC',
    icon: 'https://www.keycloak.org/resources/images/keycloak_icon_512px.png',
  },

  eventbrite: {
    color: '#F55A20',
    icon: 'https://cdn-icons-png.flaticon.com/512/888/888874.png',
  },

  toshl: {
    color: '#FFCC00',
    icon: 'https://toshl.com/favicon.ico',
  },

  passage: {
    color: '#00B2A9',
    icon: 'https://passage.id/favicon.ico',
  },

  xing: {
    color: '#026466',
    icon: 'https://cdn-icons-png.flaticon.com/512/1409/1409931.png',
  },

  foursquare: {
    color: '#F94877',
    icon: 'https://cdn-icons-png.flaticon.com/512/888/888874.png',
  },

  thirtySevenSignals: {
    color: '#2DDE98',
    icon: 'https://cdn-icons-png.flaticon.com/512/888/888874.png',
  },

  jira: {
    color: '#0052CC',
    icon: 'https://cdn-icons-png.flaticon.com/512/888/888874.png',
  },
};
