import LinksBlock from '@components/pages/search/components/shared-blocks/links';
import { WSResponseType } from '@state/middleware/types';
import { RootType } from '@state/store';
import { useSelector } from 'react-redux';

export default function StreamingLinks() {
  const searchResults = useSelector<RootType, WSResponseType>(
    (state) => state.websocket.WSResponse,
  );

  const links = searchResults.searchResult.layout?.find(
    (block) => block?.type === 'links',
  );

  return <LinksBlock links={links?.links} />;
}
