import { StyledAppBar, StyledToolbar } from '@layouts/styles';
import { AppBarProps } from '@mui/material';
import Container from '@mui/material/Container';

export default function HeaderWrapper({
  children,
  position = 'fixed',
}: React.PropsWithChildren<{ position?: AppBarProps['position'] }>) {
  return (
    <Container maxWidth={false}>
      <StyledAppBar position={position}>
        <StyledToolbar component="nav" sx={{ px: { md: 6 }, py: 3 }}>
          {children}
        </StyledToolbar>
      </StyledAppBar>
    </Container>
  );
}
