//TODO: for translation service we will have an issue with Yup validation messages
import en from '@translations/en';
import * as Yup from 'yup';

export const enterEmailSchema = Yup.object().shape({
  email: Yup.string()
    .email(en.layout.auth.desktop.modal.steps.enterEmail.form.email.invalid)
    .required(
      en.layout.auth.desktop.modal.steps.enterEmail.form.email.required,
    ),
});

export const verifyEmailSchema = Yup.object().shape({
  code: Yup.string().required(
    en.layout.auth.desktop.modal.steps.verifyEmail.form.code.required,
  ),
});

export const passwordSchema = Yup.object().shape({
  password: Yup.string().required(),
  intent: Yup.string().required(),
});

export const enterPasswordSchema = Yup.object().shape({
  password: Yup.string().required(
    en.layout.auth.desktop.modal.steps.enterPassword.form.password.required,
  ),
});

export const setupPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required(
      en.layout.auth.desktop.modal.steps.setupPassword.form.password.required,
    )
    .min(
      8,
      en.layout.auth.desktop.modal.steps.setupPassword.form.password.minChar,
    ),
  is_subscribe_marketing: Yup.boolean(),
});

export const setupNameSchema = Yup.object().shape({
  first_name: Yup.string().required(
    en.layout.auth.desktop.modal.steps.setupName.form.name.required,
  ),
});
