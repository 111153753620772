import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

export const StyledStackButtons = styled(Stack)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'inherit',
  },
  gap: theme.spacing(2),
}));

export const StyledStack = styled(Stack)(() => ({
  alignItems: 'center',
}));
