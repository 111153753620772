import Home from '@components/pages/home';
import { threads } from '@lib/agent';
import paths from '@router/paths';
import { ActionFunctionArgs } from 'react-router-dom';

export default function HomePage() {
  return <Home />;
}

const MAX_THREAD_NAME_LENGTH = 60;
const DOTS_LENGTH = 3;

export function action() {
  return async ({ request }: ActionFunctionArgs) => {
    const formData = await request.formData();

    const prompt = formData.get('prompt')?.toString().trim();

    if (typeof prompt !== 'string' || prompt.length === 0) return null;

    let truncatedPrompt = undefined as string | undefined;

    if (prompt.length > MAX_THREAD_NAME_LENGTH) {
      truncatedPrompt =
        prompt.slice(0, MAX_THREAD_NAME_LENGTH - DOTS_LENGTH) + '...';
    }

    async function createOneThread() {
      const { data } = await threads.createOneThread({
        name: truncatedPrompt || prompt!,
        type: 'search',
      });

      return {
        prompt,
        threadId: String(data.id),
        redirect: `${paths.search.pathname}?t=${data.id}`,
        isUserRedirected: true,
      };
    }

    try {
      return await createOneThread();
    } catch (error: unknown) {
      throw new Error(`An error occurred while creating the thread: ${error}`);
    }
  };
}
