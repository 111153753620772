import HistoryBlocks from '@components/pages/search/sections/history-blocks';
import SearchResult from '@components/pages/search/sections/web-socket-blocks';
import { StyledContainerForFixedHeader } from '@components/pages/styles';

export default function Search() {
  return (
    <StyledContainerForFixedHeader maxWidth="xl">
      <HistoryBlocks />
      <SearchResult />
    </StyledContainerForFixedHeader>
  );
}
