import Header from '@components/pages/knowledge-base/components/header';
import AddKnowledgeBaseModal from '@components/pages/knowledge-base/components/modals/add-knowledge-base';
import CreateFolderModal from '@components/pages/knowledge-base/components/modals/create-folder';
import Table from '@components/pages/knowledge-base/components/table';
import {
  Columns,
  ROWS,
} from '@components/pages/knowledge-base/components/table/mock';
import {
  StyledCheckbox,
  StyledCheckboxIcon,
  StyledIntermittentCheckbox,
  StyledStack,
} from '@components/pages/knowledge-base/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import AddIcon from '@mui/icons-material/Add';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import { ColumnDef } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import ActionButton from '@components/pages/knowledge-base/components/action-button';
import StatusLabel from '@components/pages/knowledge-base/components/status-label';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';

export default function AdminKnowledgeBase() {
  const { t } = useTranslation();
  const [isAddKnowledgeModalOpen, setIsAddKnowledgeModalOpen] = useState(false);
  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);

  function handleToggleAddKnowledgeModal() {
    setIsAddKnowledgeModalOpen(!isAddKnowledgeModalOpen);
  }

  function handleToggleCreateFolderModal() {
    setIsCreateFolderModalOpen(!isCreateFolderModalOpen);
  }

  const columns = useMemo<ColumnDef<Columns>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <Checkbox
            color="primary"
            icon={<StyledCheckboxIcon />}
            checkedIcon={<StyledCheckbox />}
            indeterminateIcon={<StyledIntermittentCheckbox />}
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            icon={<StyledCheckboxIcon />}
            checkedIcon={<StyledCheckbox />}
            indeterminateIcon={<StyledIntermittentCheckbox />}
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
      },
      {
        header: 'Name',
        accessorKey: 'name',
        cell: ({ getValue }) => {
          const name = getValue<Columns['name']>();
          return (
            <Stack direction="row" gap={2}>
              <span>{name.icon}</span>
              <span>{name.label}</span>
            </Stack>
          );
        },
      },
      {
        header: 'Format',
        accessorKey: 'format',
        cell: ({ getValue }) => {
          const format = getValue<Columns['format']>();
          return (
            <Stack direction="row" gap={2}>
              <span>{format.icon}</span>
              <span>{format.label}</span>
            </Stack>
          );
        },
      },
      {
        header: 'Location',
        accessorKey: 'location',
        cell: ({ getValue }) => {
          const location = getValue<Columns['location']>();
          return (
            <Stack direction="row" gap={2}>
              <span>{location.icon}</span>
              <span>{location.label}</span>
            </Stack>
          );
        },
      },
      {
        header: 'Sync Status',
        accessorKey: 'status',
        cell: ({ getValue }) => {
          const status = getValue<Columns['status']>();
          getValue<Columns['status']>();
          return <StatusLabel status={status} />;
        },
      },
      {
        header: 'Permission',
        accessorKey: 'permission',
        cell: ({ getValue }) => {
          return (
            <Stack direction="row">
              {getValue().map((user, i) => (
                <Avatar
                  key={i}
                  sx={{ ...(i !== 0 && { marginLeft: -2 }) }}
                  src={user.avatar}
                  alt={user.name}
                />
              ))}
            </Stack>
          );
        },
      },
      {
        header: 'Action',
        accessorKey: 'action',
        cell: () => (
          <Stack direction="row" gap={2}>
            <ActionButton>
              <SettingsOutlinedIcon />
            </ActionButton>
            <ActionButton>
              <DeleteOutlineOutlinedIcon />
            </ActionButton>
          </Stack>
        ),
      },
    ],
    [],
  );

  return (
    <StyledStack gap={6}>
      <CreateFolderModal
        open={isCreateFolderModalOpen}
        onClose={handleToggleCreateFolderModal}
      />
      <AddKnowledgeBaseModal
        open={isAddKnowledgeModalOpen}
        onClose={handleToggleAddKnowledgeModal}
      />
      <Header
        title={t('page.knowledgeBase.admin.header.title', {
          defaultValue: 'Knowledge Base',
        })}
        description={t('page.knowledgeBase.admin.header.description', {
          defaultValue: 'Manage your knowledge',
        })}
        buttons={{
          primary: {
            icon: <AddIcon />,
            label: t('page.knowledgeBase.admin.header.buttons.primary.label', {
              defaultValue: 'Add knowledge',
            }),
            onClick: handleToggleAddKnowledgeModal,
          },
          secondary: {
            icon: <CreateNewFolderOutlinedIcon />,
            label: t(
              'page.knowledgeBase.admin.header.buttons.secondary.label',
              {
                defaultValue: 'Create a folder',
              },
            ),
            onClick: handleToggleCreateFolderModal,
          },
        }}
      />
      <Table data={ROWS} columns={columns} />
      {/* <UppyUpload /> */}
    </StyledStack>
  );
}
