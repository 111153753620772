import axiosApiInstance from '@lib/agent/services/api/axiosInstance';
import axiosDesygnerInstance from '@lib/agent/services/desygner/axiosInstance';
import { UserType } from '@shared-types/auth';
import { setJwt } from '@components/pages/command-designer/sections/root-editor/run.service';
import { jwtDecode } from 'jwt-decode';

export function isValidToken(token: string) {
  try {
    const decoded = getDecodedToken(token);
    const currentTime = Date.now() / 1000;

    if (!decoded) {
      throw new Error('Token is not verified');
    }

    return decoded.exp > currentTime;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export function setSession(jwt: string | null) {
  if (jwt) {
    localStorage.setItem('token', jwt);
    axiosApiInstance.defaults.headers.common.Authorization = `Bearer ${jwt}`;
    axiosDesygnerInstance.defaults.headers.common.Authorization = `Bearer ${jwt}`;
    setJwt(jwt);
  } else {
    localStorage.removeItem('token');
    delete axiosApiInstance.defaults.headers.common.Authorization;
    delete axiosDesygnerInstance.defaults.headers.common.Authorization;
    setJwt(null);
  }
}

export function checkUserAuthType(token: string) {
  if (!token) return;

  const decodedJWT = getDecodedToken(token);
  return decodedJWT.auth_type;
}

export function getDecodedToken(token: string): UserType {
  return jwtDecode(token);
}
