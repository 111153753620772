/* eslint-disable import/prefer-default-export */

import { styled } from '@mui/material';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import { NavLinkProps } from 'react-router-dom';

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadius * 100,
  padding: 0,
  marginBottom: theme.spacing(2),
})) as React.ComponentType<ListItemProps & NavLinkProps>;
