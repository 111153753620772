//TODO: there are some static CSS rule sets that need to be dynamic.
import usePromptHistoryState from '@components/pages/hooks/usePromptHistoryState';
import useRedirectHistoryState from '@components/pages/hooks/useRedirectHistoryState';
import Layout from '@components/pages/search/components/shared-blocks/layout';
import LoadingSkeleton from '@components/pages/search/sections/loading-skeleton';
import StreamingAnswer from '@components/pages/search/sections/web-socket-blocks/streaming-answer';
import StreamingFollowups from '@components/pages/search/sections/web-socket-blocks/streaming-followups';
import StreamingInfo from '@components/pages/search/sections/web-socket-blocks/streaming-info';
import StreamingLinks from '@components/pages/search/sections/web-socket-blocks/streaming-links';
import StreamingPhotos from '@components/pages/search/sections/web-socket-blocks/streaming-photos';
import StreamingPrompt from '@components/pages/search/sections/web-socket-blocks/streaming-prompt';
import StreamingSinglePhotosCol from '@components/pages/search/sections/web-socket-blocks/streaming-single-photos-col';
import SearchForm from '@components/search-form';
import useGeolocation from '@hooks/useGeoLocation';
import useGetThreadIdFromQueryParam from '@hooks/useGetThreadFromQueryParam';
import { useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { queryClient } from '@providers/ReactQueryProvider';
import { WSResponseType } from '@state/middleware/types';
import { RootType } from '@state/store';
import { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function SearchResult() {
  const { threadId } = useGetThreadIdFromQueryParam();
  const dispatch = useDispatch();
  const { state, clearHistoryStateRedirect } = useRedirectHistoryState();
  const WSMessageStatus = useSelector<RootType, 'started' | 'finished' | null>(
    (state) => state.websocket.WSMessageStatus,
  );
  const WSResponse = useSelector<RootType, WSResponseType>(
    (state) => state.websocket.WSResponse,
  );
  const { historyStatePrompt } = usePromptHistoryState();
  const isWSLoading = useSelector<RootType, boolean>(
    (state) => state.websocket.isWSLoading,
  );
  const askAIErrorStatus = useSelector(
    (state: RootType) => state.searchResult.askAIErrored?.status,
  );
  const theme = useTheme();
  const { locationData, loading } = useGeolocation();

  useEffect(() => {
    if (
      threadId == null ||
      loading ||
      historyStatePrompt == null ||
      !state?.isUserRedirected
    ) {
      return;
    }

    dispatch({
      type: 'searchResult/askAIEmitted',
      payload: {
        threadId,
        prompt: historyStatePrompt,
        latlng: locationData,
      },
    });
    //? revalidate threads for search in history
    queryClient.invalidateQueries({
      queryKey: ['threads', 'search'],
    });
    clearHistoryStateRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.isUserRedirected, loading, threadId]);

  const handleFetchProgrammatically = useCallback(
    async (prompt: string) => {
      if (locationData == null || threadId == null) return;
      dispatch({
        type: 'searchResult/askAIEmitted',
        payload: {
          threadId,
          prompt,
          latlng: locationData,
        },
      });
      window.history.pushState(
        {
          prompt,
        },
        '',
        null,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locationData, threadId],
  );

  const SearchBar = memo(({ disabled }: { disabled?: boolean }) => (
    <SearchForm
      disabled={disabled}
      reactRouterFormProps={{
        method: 'POST',
        onSubmit: (e) => {
          e.preventDefault();
          const formData = new FormData(e.currentTarget);
          const text = formData.get('text');
          if (text == null || text instanceof File) return;
          handleFetchProgrammatically(text);
        },
      }}
      searchTextFieldProps={{
        name: 'text',
        disabled,
        sx: {
          position: 'fixed',
          zIndex: theme.zIndex.fab,
          margin: {
            xs: theme.spacing(0, 2),
            md: theme.spacing(0, '12%'),
          },
          width: {
            xs: 'inherit',
            md: '530px',
          },
          bottom: {
            xs: theme.spacing(22.5),
            md: theme.spacing(5),
          },
          left: {
            xs: 0,
            md: 'inherit',
          },
          right: {
            xs: 0,
            md: 'inherit',
          },
          borderRadius: '100px',
          border: `5px solid ${theme.palette.background.default}`,
          background: theme.palette.background.card.main,
        },
      }}
    />
  ));

  if (askAIErrorStatus != null) {
    return <SearchBar />;
  }

  if (WSResponse.searchResult.TOS != null) {
    return (
      <>
        <SearchBar />
        <Grid container>
          <Grid item md={12}>
            <Card>
              <Typography variant="h4">
                {WSResponse.searchResult.TOS}
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }

  if (isWSLoading) {
    return <LoadingSkeleton />;
  }

  if (WSMessageStatus == null) {
    return (
      <SearchBar disabled={isWSLoading || WSMessageStatus === 'started'} />
    );
  }

  return (
    <Layout
      answer={<StreamingAnswer />}
      info={<StreamingInfo />}
      followups={<StreamingFollowups />}
      prompt={<StreamingPrompt />}
      links={<StreamingLinks />}
      photos={<StreamingPhotos />}
      singlePhotosCol={<StreamingSinglePhotosCol />}
      searchForm={<SearchBar disabled={WSMessageStatus === 'started'} />}
    />
  );
}
