import useUserAuth from '@components/user-authentication/hooks/useUserAuth';
import { useTranslation } from '@desygner/ui-common-translation';
import useAuth from '@hooks/useAuth';
import useSearchParamsState from '@hooks/useSearchParamsState';
import { signUp } from '@lib/auth/recaptcha';
import LogoutIcon from '@mui/icons-material/Logout';
import Button, { ButtonProps } from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { toast } from 'react-toastify';

type TrimmedButtonProps = Omit<ButtonProps, 'label'>;

const DESKTOP_BUTTONS: Array<
  TrimmedButtonProps & {
    mappedLabel:
      | 'page.auth.mobile.buttons.primary'
      | 'page.auth.mobile.buttons.secondary';
    defaultLabel: 'Login' | 'Sign Up';
  }
> = [
  {
    mappedLabel: 'page.auth.mobile.buttons.secondary',
    defaultLabel: 'Login',
    variant: 'outlined',
    color: 'primary',
    id: 'login',
  },
  {
    mappedLabel: 'page.auth.mobile.buttons.primary',
    defaultLabel: 'Sign Up',
    variant: 'contained',
    color: 'primary',
    id: 'sign-up',
  },
];

export default function AuthButton() {
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParamsState('action', '');
  const { me, handleUpdateMe } = useAuth();
  const { resetForm } = useUserAuth();

  function openModal() {
    setSearchParams('secure-account');
  }

  async function handleLogout() {
    window.localStorage.removeItem('token');
    resetForm();
    toast.success(
      t('response.success.logout', {
        defaultValue: 'You have successfully logged out',
      }),
    );

    const recaptchaToken = window.localStorage.getItem('_grecaptcha');
    if (recaptchaToken) {
      const response = await signUp(recaptchaToken);
      if (response?.data.status === 'Ok') {
        window.localStorage.removeItem('fingerPrintId');
        handleUpdateMe(response.data.token);
      }
    } else {
      window.location.reload();
    }
  }

  if (me && (me.auth_type === 'email' || me.auth_type === 'oauth2')) {
    return (
      <Button
        onClick={handleLogout}
        variant="outlined"
        endIcon={<LogoutIcon />}
      >
        {t('layout.auth.logout', {
          defaultValue: 'Logout',
        })}
      </Button>
    );
  }

  return (
    <Stack direction="row" gap={2}>
      {DESKTOP_BUTTONS.map(({ defaultLabel, mappedLabel, id, ...other }) => {
        return (
          <Button key={mappedLabel} {...other} onClick={openModal}>
            {t(mappedLabel, { defaultValue: defaultLabel })}
          </Button>
        );
      })}
    </Stack>
  );
}
