import AuthProvider from '@providers/AuthProvider';
import ReduxProvider from '@providers/ReduxProvider';
import ThemeSettingsProvider from '@providers/ThemeSettingsProvider';
import ReactQueryProvider from './ReactQueryProvider';
import ThemeProvider from './ThemeProvider';
import TranslationProvider from './TranslationProvider';
import PortalProvider from './PortalProvider';
import WebSocketProvider from '@providers/WebSocketProvider';

export default function Providers({ children }: React.PropsWithChildren) {
  return (
    <ReactQueryProvider>
      <ReduxProvider>
        <WebSocketProvider>
          <ThemeSettingsProvider>
            <ThemeProvider>
              <TranslationProvider>
                <AuthProvider>
                  <PortalProvider>{children}</PortalProvider>
                </AuthProvider>
              </TranslationProvider>
            </ThemeProvider>
          </ThemeSettingsProvider>
        </WebSocketProvider>
      </ReduxProvider>
    </ReactQueryProvider>
  );
}
