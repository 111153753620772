import requests from '@lib/agent/requests';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';
import { CreditUsageResponseType } from '@shared-types/agent';

export const payments = {
  getCredit: () =>
    requests(axiosApiInstance).get<CreditUsageResponseType>(
      '/v1/entities/credits',
    ),
  getStripePaymentLink: <T>() =>
    requests(axiosApiInstance).get<T>(
      '/v1/payment/gateway/stripe/checkout-session',
    ),
  getStripePortalLink: <T>() =>
    requests(axiosApiInstance).get<T>(
      '/v1/payment/gateway/stripe/portal-session',
    ),
};

export default payments;
