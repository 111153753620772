import useGetCreditAsPercentage from '@hooks/useGetCreditAsPercentage';
import {
  StyledBox,
  StyledSlider,
  StyledStack,
} from '@layouts/main/sections/toolbar/mini/desktop/sections/credit-usage/styles';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

const STAR_SIZE = 20;

export default function CreditUsage() {
  const { creditsData, isCreditLoading } = useGetCreditAsPercentage();

  return (
    <StyledBox>
      <StyledStack direction="column" gap={2}>
        <img
          src="https://assets.knowz.com/assets/images/star.png"
          alt="star"
          width={STAR_SIZE}
          height={STAR_SIZE}
        />
        <StyledSlider value={creditsData || 0} orientation="vertical" />
        {isCreditLoading ? (
          <Skeleton variant="rectangular" width={35} height={20} />
        ) : (
          <Typography variant="overline" sx={{ fontWeight: 400 }}>
            {creditsData! < 0 ? 0 : Math.round(Number(creditsData))}%
          </Typography>
        )}
      </StyledStack>
    </StyledBox>
  );
}
