import FormProvider from '@components/react-hook-form/FormProvider';
import RHFTextField from '@components/react-hook-form/RHFTextField';
import FormHeading from '@components/user-authentication/components/form-heading';
import usePromotiveSignUp from '@components/user-authentication/hooks/usePromotiveSignUp';
import useUserAuth from '@components/user-authentication/hooks/useUserAuth';
import { setupNameSchema } from '@components/user-authentication/schemas';
import { useTranslation } from '@desygner/ui-common-translation';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';

const defaultValues = {
  first_name: '',
};

type SetupNameFormType = {
  first_name: string;
};

export default function SetupName() {
  const { code, email, password, isSubscribe, handlePassword } = useUserAuth();

  const { t } = useTranslation();

  const methods = useForm<SetupNameFormType>({
    resolver: yupResolver(setupNameSchema),
    defaultValues,
  });

  const {
    formState: { errors, isDirty, isSubmitting },
    handleSubmit,
  } = methods;

  const { mutate: mutatePromotiveSignUp, isPending: isPromotiveSignUpPending } =
    usePromotiveSignUp();

  const isBusy = isSubmitting || isPromotiveSignUpPending;

  function onSubmit(data: SetupNameFormType) {
    mutatePromotiveSignUp({
      code,
      email,
      first_name: data.first_name,
      password,
      is_subscribe_marketing: isSubscribe,
    });
    //? we remove password from state for security reasons
    handlePassword('');
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <FormHeading
        heading={t('layout.auth.desktop.modal.steps.setupName.label', {
          defaultValue: 'Create your account',
        })}
        withBackButton
      />
      <Stack gap={5}>
        <Typography component="span" variant="body2">
          {t('layout.auth.desktop.modal.steps.setupName.description', {
            defaultValue: 'Tell us a bit about yourself.',
          })}
        </Typography>
        <RHFTextField
          size="small"
          placeholder={t(
            'layout.auth.desktop.modal.steps.setupName.form.name.placeholder',
            {
              defaultValue: 'Your name',
            },
          )}
          disabled={isBusy}
          name="first_name"
          error={!!errors.first_name?.message}
          fullWidth
          helperText={errors.first_name?.message}
        />
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={!isDirty || isBusy}
          onClick={handleSubmit(onSubmit)}
          loading={isBusy}
          fullWidth
        >
          {t('layout.auth.desktop.modal.steps.setupName.form.button', {
            defaultValue: 'Create your account',
          })}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
