import {
  Columns,
  ROWS,
} from '@components/pages/knowledge-base/components/table/mock';
import {
  StyledMuiTableHeadCell,
  StyledPaper,
} from '@components/pages/knowledge-base/components/table/styles';
import Box from '@mui/material/Box';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import TablePaginationActions from '@components/pages/knowledge-base/components/table/TablePaginationActions';
import MuiTableRow from '@mui/material/TableRow';
import MuiTableSortLabel from '@mui/material/TableSortLabel';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import MuiTable from '@mui/material/Table';
import { TablePagination } from '@mui/material';
import SwapVertIcon from '@mui/icons-material/SwapVert';

type Props = {
  data: typeof ROWS;
  columns: ColumnDef<Columns>[];
};

export default function Table({ data, columns }: Props) {
  const tableData = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    enableRowSelection: true,
  });

  const { pageSize, pageIndex } = tableData.getState().pagination;

  return (
    <Box>
      <StyledPaper>
        <MuiTable>
          <MuiTableHead>
            <MuiTableRow>
              {tableData.getHeaderGroups().map((headerGroup) => {
                return headerGroup.headers.map((header) => {
                  const isItSelectCell = header.id === 'select';
                  const areTheySelectOrActionCell =
                    isItSelectCell || header.id === 'action';
                  return (
                    <StyledMuiTableHeadCell
                      key={header.id}
                      sx={{
                        ...(isItSelectCell && {
                          width: '10px',
                        }),
                      }}
                    >
                      <MuiTableSortLabel
                        active={!areTheySelectOrActionCell}
                        hideSortIcon={areTheySelectOrActionCell}
                        IconComponent={SwapVertIcon}
                        sx={{
                          width: '100%',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </span>
                      </MuiTableSortLabel>
                    </StyledMuiTableHeadCell>
                  );
                });
              })}
            </MuiTableRow>
          </MuiTableHead>
          <MuiTableBody>
            {tableData.getRowModel().rows.map((row) => {
              return (
                <MuiTableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <MuiTableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </MuiTableCell>
                    );
                  })}
                </MuiTableRow>
              );
            })}
          </MuiTableBody>
        </MuiTable>
      </StyledPaper>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: data.length }]}
        component="div"
        count={tableData.getFilteredRowModel().rows.length}
        rowsPerPage={pageSize}
        page={pageIndex}
        slotProps={{
          select: {
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          },
        }}
        onPageChange={(_, page) => {
          tableData.setPageIndex(page);
        }}
        onRowsPerPageChange={(e) => {
          const size = e.target.value ? Number(e.target.value) : 5;
          tableData.setPageSize(size);
        }}
        ActionsComponent={TablePaginationActions}
      />
    </Box>
  );
}
