import { styled } from '@mui/material';

const StyledSelectedOption = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
  border: `1px solid ${theme.palette.background.card.main}`,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2, 3),
  alignItems: 'center',

  '&:hover': {
    background: theme.palette.background.card.main,

    '& *': {
      color: theme.palette.primary.main,
      fill: theme.palette.error.main,
    },
  },
}));

export default StyledSelectedOption;
