import { useTranslation } from '@desygner/ui-common-translation';
import useAuth from '@hooks/useAuth';
import useSearchParamsState from '@hooks/useSearchParamsState';
import { checkUserAuthType } from '@lib/jwt';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { payments } from '@lib/agent';
import StyledStack from '@layouts/main/sections/toolbar/expanded/sections/purchased-ai-credit/styles';
import { useState } from 'react';

export default function PurchasedAICredit() {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [, setSearchParams] = useSearchParamsState('action', '');
  const { handleIsUserWaitingForUpgrade, me } = useAuth();

  function toggleDialog() {
    const token = window.localStorage.getItem('token')!;
    const authType = checkUserAuthType(token);
    if (authType === 'web_fingerprint') {
      setSearchParams('secure-account');
      handleIsUserWaitingForUpgrade(true);
      return;
    }
    setSearchParams('upgrade-account');
  }

  async function managePayments() {
    setIsLoading(true);
    const response = await payments.getStripePortalLink<{ url: string }>();
    setIsLoading(false);
    window.open(response?.data.url);
  }

  if (me?.paid) {
    return (
      <StyledStack className="mini" gap={1}>
        <LoadingButton
          variant="contained"
          fullWidth
          loading={isLoading}
          onClick={managePayments}
        >
          {t('layout.toolbar.manageAICredit.button', {
            defaultValue: 'Manage payments',
          })}
        </LoadingButton>
      </StyledStack>
    );
  }

  return (
    <StyledStack gap={1}>
      <Typography variant="body2">
        {t('layout.toolbar.buyAICredit.title', {
          defaultValue: 'Top Up Your AI Credits!',
        })}
      </Typography>

      <Typography variant="caption" color="text.secondary">
        {t('layout.toolbar.buyAICredit.subtitle', {
          defaultValue:
            'Purchase more credits to unlock unlimited AI potential!',
        })}
      </Typography>

      <Button
        fullWidth
        onClick={toggleDialog}
        variant="contained"
        sx={{ mt: 3 }}
      >
        {t('layout.toolbar.buyAICredit.button', {
          defaultValue: 'Buy more!',
        })}
      </Button>
    </StyledStack>
  );
}
