import {
  StyledDashboard,
  StyledDragDrop,
} from '@components/uppy-upload/styles';
import { Uppy } from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/drag-drop/dist/style.css';
import RemoteSources from '@uppy/remote-sources';
import { useState } from 'react';

const COMPANION_URL = 'http://companion.uppy.io';

function createUppyInstance() {
  return new Uppy({
    debug: true,
    restrictions: {
      allowedFileTypes: [
        'audio/mpeg',
        'audio/ogg',
        'audio/wav',
        'audio/webm',
        'audio/x-m4a',
        'audio/x-wav',
        'audio/x-ms-wma',
        'text/plain',
        'text/csv',
        'text/html',
        'text/markdown',
        'application/pdf',
        'application/msword',
        'application/vnd.ms-excel',
        'application/json',
        'application/xml',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ],
    },
  }).use(RemoteSources, {
    companionUrl: COMPANION_URL,
  });
}

export default function UppyUpload() {
  const [uppy] = useState(createUppyInstance());

  return (
    <>
      <StyledDragDrop
        note="audio, text and docs, up to 5GB"
        uppy={uppy as never}
        locale={
          {
            strings: {
              dropHereOr: 'Drop files here or %{browse}',
              browse: 'browse files',
            },
          } as never
        }
        width="100%"
        height={450}
      />
      <StyledDashboard
        disableLocalFiles={true}
        uppy={uppy as never}
        proudlyDisplayPoweredByUppy={false}
        width="100%"
        height={250}
      />
    </>
  );
}
