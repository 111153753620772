import requests from '@lib/agent/requests';
import axiosAwsInstance from '@lib/agent/services/aws/axiosInstance';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';
import axiosFlowInstance from '@lib/agent/services/flow/axiosInstance';
import { AxiosResponse } from 'axios';
import {
  CommandType,
  CommandMinType,
  CommandDefinition,
  UpdateOneCommandParamsType,
} from '@shared-types/agent';

export const flows = {
  run: ({
    body,
  }: any): Promise<
    AxiosResponse<{
      code: number;
      output: string[];
    }>
  > => requests(axiosFlowInstance).post(`/run`, body),
};

export const commands = {
  createOneCommand: (body: CommandMinType) =>
    requests(axiosApiInstance).post<CommandType>(
      '/v1/entities/commands',
      body,
    ),
  updateOneCommand: (
    commandId: number|string,
    body: UpdateOneCommandParamsType,
  ) =>
    requests(axiosApiInstance).patch<CommandType>(
      `/v1/entities/commands/${commandId}`,
      body,
    ),
  deleteOneCommand: (commandId: number|string) =>
    requests(axiosApiInstance).delete(`/v1/entities/commands/${commandId}`),
  getAllCommands: () =>
    requests(axiosApiInstance).get<CommandMinType[]>('/v1/entities/commands'),
  getCommand: (commandId: number|string) =>
    requests(axiosApiInstance).get<CommandType>(`/v1/entities/commands/${commandId}`),
  getCommandDefinition: (signedUrl: string) =>
    requests(axiosAwsInstance).get<CommandDefinition>(signedUrl),
};

export default commands;
