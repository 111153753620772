import StyledMobileToolbar from '@layouts/main/sections/toolbar/mini/mobile/styles';
import { StyledListItem } from '@layouts/main/sections/toolbar/mini/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import CONFIG from '@layouts/main/sections/toolbar/config';
import { StyledNavLink } from '@layouts/main/sections/toolbar/styles';
import CreditUsage from '@layouts/main/sections/toolbar/mini/mobile/sections/credit-usage';
import useAuth from '@hooks/useAuth';

export default function MobileToolbar({ children }: React.PropsWithChildren) {
  const { me } = useAuth();

  return (
    <StyledMobileToolbar disableGutters>
      <Box sx={{ px: CONFIG.PATH_TO_SHOW_COLLAPSING_BUTTON }}>
        <List
          disablePadding
          direction="row"
          component={Stack}
          sx={{
            justifyContent: 'space-around',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {CONFIG.TOOLBAR_ITEMS.filter(
            ({ isAlpha }) => me?.alpha || !isAlpha,
          ).map(({ icon, url }, i) => (
            <StyledListItem
              key={i}
              disablePadding
              disableGutters
              component={StyledNavLink}
              to={url}
            >
              <IconButton>{icon}</IconButton>
            </StyledListItem>
          ))}
          <CreditUsage />
        </List>
      </Box>
      {children}
    </StyledMobileToolbar>
  );
}
