import Modal, { ModalProps } from '@mui/material/Modal';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { useTranslation } from '@desygner/ui-common-translation';
import RHFTextField from '@components/react-hook-form/RHFTextField';
import FormProvider from '@components/react-hook-form/FormProvider';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import createFolderSchema from '@components/pages/knowledge-base/components/modals/create-folder/schema';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

type Props = Omit<ModalProps, 'children'> & {
  onClose: () => void;
};

export default function CreateFolderModal({ onClose, ...otherProps }: Props) {
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: {
      name: '',
      resolver: yupResolver(createFolderSchema),
    },
  });

  const { handleSubmit } = methods;

  function onSubmit(data: { name: string }) {
    console.log('submit', data);
  }

  return (
    <Modal onClose={onClose} {...otherProps}>
      <Card
        sx={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          width: '90%',
          maxWidth: 400,
          transform: 'translate(-50%, -50%)',
        }}
        component={Stack}
        gap={4}
      >
        <Typography variant="subtitle1">
          {t('page.knowledgeBase.modals.createFolder.title', {
            defaultValue: 'Create folder',
          })}
        </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={1}>
            <Typography variant="body2">
              {t('page.knowledgeBase.modals.createFolder.title', {
                defaultValue: 'Folder name',
              })}
            </Typography>
            <RHFTextField
              size="small"
              name="name"
              placeholder={t(
                'page.knowledgeBase.modals.createFolder.form.name.placeholder',
                {
                  defaultValue: 'Enter title',
                },
              )}
            />
          </Stack>
          <Stack gap={2} direction="row" sx={{ justifyContent: 'end', mt: 6 }}>
            <Button variant="outlined" onClick={onClose}>
              {t('page.knowledgeBase.modals.createFolder.form.buttons.cancel', {
                defaultValue: 'Cancel',
              })}
            </Button>
            <LoadingButton variant="contained">
              {t(
                'page.knowledgeBase.modals.createFolder.form.buttons.submitButton',
                {
                  defaultValue: 'Save',
                },
              )}
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Card>
    </Modal>
  );
}
