import UppyUpload from '@components/uppy-upload';
import Modal, { ModalProps } from '@mui/material/Modal';
import Card from '@mui/material/Card';

export default function AddKnowledgeBaseModal(
  props: Omit<ModalProps, 'children'>,
) {
  return (
    <Modal {...props}>
      <Card
        sx={{
          maxWidth: 800,
          width: '90%',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <UppyUpload />
      </Card>
    </Modal>
  );
}
