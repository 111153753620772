import ImageDialog from '@components/pages/search/components/image-dialog';
import StyledImageListItem from '@components/pages/search/components/shared-blocks/photos/styles';
import Card from '@mui/material/Card';
import ImageList from '@mui/material/ImageList';
import { PhotoType } from '@shared-types/search-result/types';

type Props = {
  photosData: {
    photos: Array<PhotoType>;
  } | null;
  isThereAnswer: boolean;
  isThereInfo: boolean;
};

export default function Photos({
  photosData,
  isThereAnswer,
  isThereInfo,
}: Props) {
  if (photosData == null || photosData.photos.length === 0) return null;

  // //? If there is only one section and it is photos, then return null since we want to show photos in the main section with SinglePhotos component
  if (isThereAnswer === false && isThereInfo === false) {
    return null;
  }

  return (
    <Card>
      <ImageList gap={8} cols={2}>
        {photosData.photos.slice(0, 10).map((photo, i: number) => (
          <StyledImageListItem key={i}>
            <ImageDialog {...photo} />
          </StyledImageListItem>
        ))}
      </ImageList>
    </Card>
  );
}
