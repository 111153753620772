import UserAuthenticationProvider from '@components/user-authentication/providers/UserAuthenticationProvider';
import useResponsive from '@hooks/useResponsive';

export default function Authentication() {
  const isItDesktop = useResponsive('up', 'md');

  return (
    <UserAuthenticationProvider>
      {isItDesktop ? (
        <>
          <UserAuthenticationProvider.DesktopAuthButton />
          <UserAuthenticationProvider.DesktopModalStepper>
            <UserAuthenticationProvider.ActiveForm />
          </UserAuthenticationProvider.DesktopModalStepper>
        </>
      ) : (
        <>
          <UserAuthenticationProvider.MobileAuthButton />
          <UserAuthenticationProvider.MobileModalStepper>
            <UserAuthenticationProvider.ActiveForm />
          </UserAuthenticationProvider.MobileModalStepper>
        </>
      )}
    </UserAuthenticationProvider>
  );
}
