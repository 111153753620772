import { useEffect, useState } from 'react';

const getCoords = async () => {
  const pos = await new Promise(
    (resolve: PositionCallback, reject: PositionErrorCallback) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    },
  );

  return {
    lat: pos.coords.latitude,
    lng: pos.coords.longitude,
  };
};

export default function useGeolocation(): {
  locationData: Array<number>;
  loading: boolean;
} {
  const [locationData, setLocationData] = useState<Array<number>>([0, 0]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getUserLocation() {
      try {
        const coords = await getCoords();
        setLocationData([coords.lat, coords.lng]);
      } catch (error) {
        console.error('an Error occurred while getting user location', error);
        setLocationData([0, 0]);
      } finally {
        setLoading(false);
      }
    }
    getUserLocation();
  }, []);

  return { locationData, loading };
}
