import requests, { responseBody } from '@lib/agent/requests';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';
import axiosAwsInstance from '@lib/agent/services/aws/axiosInstance';
import { PolicyType, CredentialsType } from '@shared-types/storage';

type PolicyRequestType = {
  type: string;
  key: string;
  size: number;
  context: any
}

type FileUploadType = {
  url: string;
  contentType: string;
  body: string;
}

export const storage = {
  getPolicy: (body: PolicyRequestType) =>
    requests(axiosApiInstance).post<PolicyType>('/v1/actions/storage/policy', body),
  getCredentials: (s3Key: string) =>
    requests(axiosApiInstance).get<CredentialsType>(`/v1/actions/storage/credentials?key=${s3Key}`),
  uploadFile: ({ url, contentType, body }: FileUploadType) =>
    axiosAwsInstance.put<any>(url, body, {
      headers: { 'Content-Type': contentType }
    }).then(responseBody)
};

export default storage;

