import Logo from '@components/logo';
import useUserAuth from '@components/user-authentication/hooks/useUserAuth';
import useAuth from '@hooks/useAuth';
import useSearchParamsState from '@hooks/useSearchParamsState';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';

const MODAL_WIDTH = 848;
const MODAL_HEIGHT = 600;

const IMAGE_PLACEHOLDER_WIDTH = 424;
const IMAGE_PLACEHOLDER_HEIGHT = 600;

export default function ModalStepper({ children }: React.PropsWithChildren) {
  const [searchParams, setSearchParams] = useSearchParamsState('action', '');
  const theme = useTheme();
  const { resetForm } = useUserAuth();
  const { handleAttemptedToLogin } = useAuth();

  function closeModal() {
    resetForm();
    handleAttemptedToLogin(true);
    setSearchParams('');
  }

  return (
    <Modal keepMounted open={searchParams.includes('secure-account')}>
      <Fade in={searchParams.includes('secure-account')}>
        <Stack
          direction="row"
          sx={{
            transform: 'translate(-50%, -50%)',
            top: '50%',
            left: '50%',
            position: 'fixed',
            width: MODAL_WIDTH,
            height: MODAL_HEIGHT,
            backgroundColor: 'background.card.light',
            borderRadius: 4,
          }}
        >
          <IconButton
            onClick={closeModal}
            sx={{
              position: 'absolute',
              left: '100%',
              ml: 2,
              '&, &:hover': {
                backgroundColor: 'common.white',
                color: 'common.black',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <Stack sx={{ p: 8, width: '100%' }}>
            <Logo />
            {children}
          </Stack>
          <Box
            component="img"
            src={
              theme.palette.mode === 'dark'
                ? 'https://assets.knowz.com/assets/images/Knowz+login-dark.jpg'
                : 'https://assets.knowz.com/assets/images/Knowz+login-light.jpg'
            }
            alt="placeholder"
            width={IMAGE_PLACEHOLDER_WIDTH}
            height={IMAGE_PLACEHOLDER_HEIGHT}
            sx={{
              borderRadius: (theme) => theme.spacing(0, 4, 4, 0),
            }}
          />
          {/* Stepper Component goes here */}
        </Stack>
      </Fade>
    </Modal>
  );
}
