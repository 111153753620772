//TODO: for translation service we will have an issue with Yup validation messages
import en from '@translations/en';
import * as Yup from 'yup';

const addAssistantsSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(60, en.page.assistants.drawer.addAssistants.form.name.max)
    .required(en.page.assistants.drawer.addAssistants.form.name.required),
  description: Yup.string()
    .trim()
    .required(
      en.page.assistants.drawer.addAssistants.form.description.required,
    ),
  supports_public_fallback: Yup.boolean(),
  supports_public_default: Yup.boolean(),
  voice: Yup.string().default('Intellectual and Analytical'),
  personality: Yup.string().default('Professional and Formal'),
  response_style: Yup.string().default('Moderate and Informative'),
});

export default addAssistantsSchema;
