import requests from '@lib/agent/requests';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';
import { SourceType } from '@shared-types/sources';

export const sources = {
  getAll: () =>
    requests(axiosApiInstance).get<SourceType[]>('/v1/entities/sources'),
};

export default sources;
