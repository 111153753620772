import paths from '@router/paths';

const NAV_ITEMS = [
  {
    label: 'layout.main.sections.header.navigation.first',
    defaultLabel: 'Product',
    link: paths.product.pathname,
  },
  {
    label: 'layout.main.sections.header.navigation.second',
    defaultLabel: 'Solutions',
    link: paths.solutions.pathname,
  },
  {
    label: 'layout.main.sections.header.navigation.third',
    defaultLabel: 'Integration',
    link: paths.integration.pathname,
  },
];

const CONFIG = {
  DRAWER_WIDTH: 280,
  NAV_ITEMS,
};

export default CONFIG;
