//TODO: for now we only show first info from the whole array since we are waiting for a proper UX/UI design.
import InfoBlock from '@components/pages/search/components/shared-blocks/info';
import { WSResponseType } from '@state/middleware/types';
import { RootType } from '@state/store';
import { useSelector } from 'react-redux';

export default function StreamingInfo() {
  const WSResponse = useSelector<RootType, WSResponseType>(
    (state) => state.websocket.WSResponse,
  );

  const info = WSResponse.searchResult.layout.find(
    (block) => block?.type === 'info',
  );

  return info && <InfoBlock text={info.text} />;
}
