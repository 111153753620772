import Layout from '@components/pages/search/components/shared-blocks/layout';
import Answer from '@components/pages/search/sections/loading-skeleton/sections/answer';
import Followups from '@components/pages/search/sections/loading-skeleton/sections/followups';
import Info from '@components/pages/search/sections/loading-skeleton/sections/info';
import Links from '@components/pages/search/sections/loading-skeleton/sections/links';
import Photos from '@components/pages/search/sections/loading-skeleton/sections/photos';
import Prompt from '@components/pages/search/sections/loading-skeleton/sections/prompt';

const DISPLAY_LIMIT = 3;
const COLS_IN_MD_SCREEN = 3;
const COLS_IN_XS_SCREEN = 6;
const SPACE_BETWEEN = 4;

export default function LoadingSkeleton() {
  return (
    <Layout
      answer={<Answer />}
      info={<Info />}
      links={
        <Links
          displayLimit={DISPLAY_LIMIT}
          mdSpace={COLS_IN_MD_SCREEN}
          spaceBetween={SPACE_BETWEEN}
          xsSpace={COLS_IN_XS_SCREEN}
        />
      }
      photos={<Photos />}
      prompt={<Prompt />}
      followups={<Followups />}
    />
  );
}
