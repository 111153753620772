import {
  //StyledImg, //TODO:
  StyledStack,
} from '@components/pages/assistants-session/welcome-message/styles';
import useGetOneAssistant from '@components/pages/hooks/useGetAssistantById';
import { Skeleton, Stack, Typography } from '@mui/material';

export default function WelcomeMessage() {
  const {
    data: assistantData,
    isLoading: isAssistantLoading,
    isFetching: isGetOneAssistantFetching,
  } = useGetOneAssistant();
  const isGetOneAssistantBusy = isAssistantLoading || isGetOneAssistantFetching;

  return (
    <StyledStack
      direction="row"
      sx={{
        backgroundColor: (theme) => theme.palette.background.card.light,
      }}
      gap={5}
    >
      {/* {assistantData && assistantData.image && (
        <StyledImg src={assistantData.image} alt={assistantData.name} />
      )} */}
      <Stack sx={{ width: '100%' }}>
        {isGetOneAssistantBusy ? (
          <Skeleton variant="text" height={40} width={120} />
        ) : (
          <Typography variant="h4" component="h1">
            {assistantData?.name}
          </Typography>
        )}
        {isGetOneAssistantBusy ? (
          <Skeleton variant="text" sx={{ width: '100%' }} />
        ) : (
          <Typography variant="body2">{assistantData?.description}</Typography>
        )}
      </Stack>
    </StyledStack>
  );
}
