import StyledSearchButton from '@components/search-form/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SearchIcon from '@mui/icons-material/Search';
import { alpha, SxProps, TextFieldProps, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Form, FormProps } from 'react-router-dom';

type Props = {
  reactRouterFormProps: FormProps;
  searchTextFieldProps?: TextFieldProps & {
    state?: 'loading' | 'idle' | 'submitting';
  };
  sx?: SxProps;
  disabled?: boolean;
};

export default function SearchForm({
  disabled,
  reactRouterFormProps,
  searchTextFieldProps,
  sx,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Form {...reactRouterFormProps} className="semantic-search-form">
      <TextField
        disabled={disabled}
        role="search"
        type="text"
        autoComplete="off"
        sx={sx}
        required
        InputProps={{
          startAdornment: <AutoAwesomeIcon sx={{ mr: theme.spacing(2.5) }} />,
          endAdornment: (
            <StyledSearchButton
              type="submit"
              variant="outlined"
              color="inherit"
              endIcon={<SearchIcon />}
              loading={
                searchTextFieldProps?.state === 'submitting' ||
                searchTextFieldProps?.state === 'loading'
              }
              disableRipple
              disableTouchRipple
              disableFocusRipple
            >
              <Box
                component="span"
                sx={{ display: { xs: 'none', md: 'block' } }}
              >
                {t('page.home.hero.search.button', {
                  defaultValue: 'Uncover secrets',
                })}
              </Box>
            </StyledSearchButton>
          ),
          placeholder: t('page.home.hero.search.placeholder', {
            defaultValue: 'Search for...',
          }),
          sx: {
            borderRadius: theme.shape.borderRadius * 10,
            pr: 0,
            height: theme.spacing(12),
            backgroundColor:
              theme.palette.mode === 'dark'
                ? alpha(theme.palette.common.white, 0.05)
                : alpha(theme.palette.common.black, 0.06),
          },
        }}
        {...searchTextFieldProps}
      />
    </Form>
  );
}
