import { forwardRef, useRef, useState, cloneElement } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import type {
  DropdownItemProps,
  DropdownOptionsProps,
  OverridePopperComponentProps,
} from '@components/dropdown/types';
import { StyledStack, StyledPopper } from '@components/dropdown/styles';

type DropdownProps = OverridePopperComponentProps & {
  trigger: React.ReactNode;
  options: DropdownOptionsProps;
};

/**
 * @description A dropdown component that takes in a trigger element and a list of options to display - NOTE: All refs passed to this component will be forwarded to the root div element
 * @param {React.ReactNode} trigger - The element that will trigger the dropdown
 * @param {DropdownOptionsProps["options"]} options - The options that will be displayed in the dropdown
 * @param {OverridePopperComponentProps} props - The props that will be passed to the Popper component of MUI
 */
const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  ({ options, trigger: TriggerElement, ...otherProps }: DropdownProps, ref) => {
    const [open, setOpen] = useState(false);

    const anchorRef = useRef<HTMLButtonElement>(null);

    const handleToggle = (value: boolean) => {
      if (value == null) return;

      if (typeof value === 'boolean') {
        setOpen(value);
      }

      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      ) {
        return;
      }

      setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      } else if (event.key === 'Escape') {
        setOpen(false);
      }
    }

    function handleListItemOnClick(
      event: React.MouseEvent<HTMLLIElement>,
      item: DropdownItemProps,
    ) {
      item.onClick(event, item.value);
      handleClose(event);
    }

    return (
      <>
        {cloneElement(TriggerElement as React.ReactElement, {
          ref: anchorRef,
          onClick: handleToggle,
          'aria-haspopup': 'listbox',
        })}
        <StyledPopper
          open={open}
          anchorEl={anchorRef.current}
          ref={ref}
          transition
          aria-expanded={open ? 'true' : 'false'}
          aria-haspopup={open ? 'true' : 'false'}
          {...otherProps}
        >
          {({ TransitionProps }) => {
            return (
              <Grow {...TransitionProps}>
                <Paper sx={{ backgroundColor: 'background.default' }}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList disablePadding onKeyDown={handleListKeyDown}>
                      {options.map((option, i) => {
                        const hasStartIcon = option.icon?.iconStart;
                        const hasEndIcon = option.icon?.iconEnd;
                        return (
                          <MenuItem
                            key={`${option.text}-${i}`}
                            onClick={(e) => handleListItemOnClick(e, option)}
                            role="option"
                            disableRipple
                            color={option?.color}
                          >
                            <StyledStack direction="row">
                              {hasStartIcon}
                              <Typography variant="body2">
                                {option.text}
                              </Typography>
                            </StyledStack>
                            {hasEndIcon}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            );
          }}
        </StyledPopper>
      </>
    );
  },
);

Dropdown.displayName = 'Dropdown';
export default Dropdown;
