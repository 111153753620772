import { sources } from '@lib/agent';
import { SourceType } from '@shared-types/sources';
import { useQuery, queryOptions } from '@tanstack/react-query';
import AsyncAutocomplete, { OptionType } from '@components/pages/command-designer/entity-selectors/AsyncAutocomplete';

export interface SourceProps {
  value: number|null;
  onChange: (value: SourceType|null) => void;
}

export default function Source(props: SourceProps) {
  const options = queryOptions({
    queryKey: ['sources'],
    queryFn: () => sources.getAll(),
  });

  const { data, isLoading } = useQuery(options);

  return <AsyncAutocomplete
    options={(data?.data||[]) as OptionType[]}
    isLoading={isLoading}
    placeholder="Select a source"
    {...props}
  ></AsyncAutocomplete>
}
