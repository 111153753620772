import useGetInfiniteSearchThreads from '@components/pages/history/hooks/useGetInfiniteSearchThreads';
import { useTranslation } from '@desygner/ui-common-translation';
import ConversationListitem from '@layouts/assistants-session/drawer/conversation-history/card';
import Heading from '@layouts/assistants-session/drawer/heading';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { useCallback, useRef } from 'react';
import Skeleton from '@layouts/assistants-session/drawer/conversation-history/skeleton';
import CONFIG from '@layouts/assistants-session/config';

const FIRST_PAGE = 0;

export default function ConversationHistory() {
  const { t } = useTranslation();
  const observer = useRef<IntersectionObserver>();
  const {
    data,
    isLoading: isGetInfiniteSearchThreadsLoading,
    hasNextPage: hasGetInfiniteSearchThreadsNextPage,
    isError: isGetInfiniteSearchThreadsError,
    error: getInfiniteSearchThreadsError,
    fetchNextPage: fetchNextGetInfiniteSearchThreadsPage,
    isFetching: isGetInfiniteSearchThreadsFetching,
  } = useGetInfiniteSearchThreads({
    type: 'assistant',
  });

  const lastElementRef = useCallback(
    (node: HTMLAnchorElement) => {
      if (isGetInfiniteSearchThreadsLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          hasGetInfiniteSearchThreadsNextPage &&
          !isGetInfiniteSearchThreadsFetching
        ) {
          fetchNextGetInfiniteSearchThreadsPage();
        }
      });

      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      fetchNextGetInfiniteSearchThreadsPage,
      hasGetInfiniteSearchThreadsNextPage,
      isGetInfiniteSearchThreadsFetching,
      isGetInfiniteSearchThreadsLoading,
    ],
  );

  if (isGetInfiniteSearchThreadsLoading) {
    return <Skeleton />;
  }

  if (data?.pages[FIRST_PAGE].data.length === 0) {
    return (
      <Alert severity="info">
        {t('emptyState.historyThreads', {
          defaultValue: 'No threads found. start a new thread',
        })}
      </Alert>
    );
  }

  if (isGetInfiniteSearchThreadsError) {
    console.error(getInfiniteSearchThreadsError.message);
    return (
      <Alert severity="error">
        {t('response.errors.code.502', {
          defaultValue: 'Unknown error. Please try again later.',
        })}
      </Alert>
    );
  }

  return (
    <Stack sx={{ p: 6, width: CONFIG.DRAWER.WIDTH }}>
      <Heading
        title={t('page.assistantsSession.drawer.title', {
          defaultValue: 'Conversation',
        })}
      />
      {data?.pages.map((page, index) => (
        <List key={index} component={Stack} gap={5}>
          {page?.data.map(
            ({ id, description, lastInteraction, name, thumbnail }) => {
              return (
                <ConversationListitem
                  ref={lastElementRef}
                  id={id}
                  lastInteraction={lastInteraction}
                  key={id}
                  name={name}
                  description={description}
                  thumbnail={thumbnail}
                />
              );
            },
          )}
          {hasGetInfiniteSearchThreadsNextPage &&
            isGetInfiniteSearchThreadsFetching && (
              <Stack sx={{ alignItems: 'center' }}>
                <CircularProgress />
              </Stack>
            )}
        </List>
      ))}
    </Stack>
  );
}
