import CONFIG from '@layouts/main/config';
import CONFIG_TOOLBAR from '@layouts/main/sections/toolbar/config';
import { StyledGlobalToolbar } from '@layouts/main/sections/toolbar/styles';
import { convertToRem } from '@lib/fonts';
import { ListItem, ListItemProps, styled } from '@mui/material';
import { NavLinkProps } from 'react-router-dom';

export const StyledExpandedToolbar = styled(StyledGlobalToolbar)(
  ({ theme }) => ({
    boxShadow: theme.customShadows.card,
    borderRadius: theme.shape.borderRadius * 4,
    height: `calc(
          100vh -
          (${convertToRem(CONFIG.HEADER.HEIGHT)} + ${convertToRem(
      CONFIG.HEADER.PADDING_Y,
    )} + ${convertToRem(CONFIG.FOOTER.HEIGHT)}))`,
    marginTop: convertToRem(CONFIG.HEADER.PADDING_Y + CONFIG.HEADER.HEIGHT),
    width: convertToRem(CONFIG_TOOLBAR.WIDTH_EXPANDED),
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.card.light,
    padding: theme.spacing(1.5),
    position: 'sticky',
    top: convertToRem(CONFIG.HEADER.HEIGHT + CONFIG.HEADER.PADDING_Y),
  }),
);

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  color: theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius * 3,
  padding: 0,
  margin: theme.spacing(1, 0),
})) as React.ComponentType<ListItemProps & NavLinkProps>;
