import { useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function usePromptHistoryState() {
  const { state } = useLocation() as { state?: { prompt: string } };
  const [historyStatePrompt, setHistoryStatePrompt] = useState(
    () => state?.prompt,
  );

  function clearHistoryStatePrompt() {
    window.history.replaceState(
      {
        ...state,
        prompt: undefined,
      },
      '',
    );
    setHistoryStatePrompt(undefined);
  }

  function setNewHistoryStatePrompt(value: string) {
    setHistoryStatePrompt(value);
    window.history.replaceState(
      {
        ...state,
        prompt: value,
      },
      '',
    );
  }

  return {
    historyStatePrompt,
    clearHistoryStatePrompt,
    setNewHistoryStatePrompt,
  };
}
