import { useLocation } from 'react-router';

export default function useRedirectHistoryState() {
  const { state } = useLocation() as { state?: { isUserRedirected: boolean } };

  function clearHistoryStateRedirect() {
    window.history.replaceState(
      {
        ...state,
        isUserRedirected: false,
        redirect: undefined,
      },
      '',
    );
  }

  return {
    state,
    clearHistoryStateRedirect,
  };
}
