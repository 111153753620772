import Logo from '@components/logo';
import UserAuthentication from '@components/user-authentication';
import usePortal from '@hooks/usePortal';
import useResponsive from '@hooks/useResponsive';
import HeaderWrapper from '@layouts/components/header-wrapper';
import CONFIG from '@layouts/main/sections/header/config';
import DesktopNav from '@layouts/main/sections/header/desktop-nav';
import NavbarDrawer from '@layouts/main/sections/header/navbar-drawer';
import { StyledStack } from '@layouts/main/sections/header/styles';
import ThemeMode from '@layouts/main/sections/header/them-mode';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import paths from '@router/paths';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export default function Header() {
  const isItDesktop = useResponsive('up', 'md');
  const isItTablet = useResponsive('down', 'md');
  const isItMobile = useResponsive('down', 'sm');
  const isItTabletOrDesktop = isItDesktop || isItTablet;
  const isItTabletOrMobile = isItTablet || isItMobile;
  const { pathname } = useLocation();
  const isHomePage = pathname === paths.home.pathname;
  const commandPath = paths.editCommand.pathname.replace(':id', '\\d+');
  const commandPathRegex = new RegExp(commandPath);
  const isCommandsEditor = commandPathRegex.test(pathname);
  const mainPortal = useRef(null);
  const { handleSetNavbarMain } = usePortal();

  useEffect(() => handleSetNavbarMain(mainPortal?.current), [mainPortal]);

  return (
    <HeaderWrapper>
      <StyledStack direction="row" gap={5}>
        <Logo isLink={!isHomePage} />
        {isItDesktop && !isCommandsEditor && (
          <DesktopNav navListItems={CONFIG.NAV_ITEMS} />
        )}
        <Box ref={mainPortal}></Box>
      </StyledStack>
      <StyledStack
        direction="row"
        sx={{
          gap: {
            xs: 2,
            md: 6,
          },
        }}
      >
        {isItTabletOrDesktop && !isCommandsEditor && <ThemeMode />}
        {isItTabletOrDesktop && (
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              display: {
                xs: 'none',
                md: 'block',
              },
            }}
          />
        )}
        <UserAuthentication />
        {isItTabletOrMobile && <NavbarDrawer navListItems={CONFIG.NAV_ITEMS} />}
      </StyledStack>
    </HeaderWrapper>
  );
}
