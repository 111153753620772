import StyledStack from '@components/user-authentication/components/form-heading/styles';
import useUserAuth from '@components/user-authentication/hooks/useUserAuth';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

type Props = {
  heading: string;
  withBackButton?: boolean;
};

export default function FormHeading({
  heading,
  withBackButton = false,
}: Props) {
  const { handleBackStep } = useUserAuth();

  function handleBackStepOnClick() {
    handleBackStep();
  }

  return (
    <StyledStack direction="row" gap={2}>
      {withBackButton && (
        <IconButton onClick={handleBackStepOnClick}>
          <ArrowBackIosNewTwoToneIcon />
        </IconButton>
      )}
      <Typography variant="h6" component="h2">
        {heading}
      </Typography>
    </StyledStack>
  );
}
