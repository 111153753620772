import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import StyledStack from '@components/pages/knowledge-base/components/header/styles';
type ButtonProps = {
  label: string;
  icon: React.ReactNode;
  onClick: () => void;
};

type Props = {
  title: string;
  description: string;
  buttons: {
    primary: ButtonProps;
    secondary: ButtonProps;
  };
};

export default function Header({ title, description, buttons }: Props) {
  return (
    <StyledStack>
      <Stack gap={2}>
        <Typography variant="h3">{title}</Typography>
        <Typography variant="body2">{description}</Typography>
      </Stack>
      <Stack direction="row" gap={3}>
        <Button
          variant="outlined"
          startIcon={buttons.secondary.icon}
          onClick={buttons.secondary.onClick}
        >
          {buttons.secondary.label}
        </Button>
        <Button
          variant="contained"
          startIcon={buttons.primary.icon}
          onClick={buttons.primary.onClick}
        >
          {buttons.primary.label}
        </Button>
      </Stack>
    </StyledStack>
  );
}
