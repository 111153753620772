//TODO: check if we get refetch on 404 which should not be in the case.
import useGetOneThread from '@components/pages/hooks/useGetOneThread';
import useAuth from '@hooks/useAuth';
import useGetThreadIdFromQueryParam from '@hooks/useGetThreadFromQueryParam';
import { threads } from '@lib/agent';
import paths from '@router/paths';
import { useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

export default function useGetHistoryBlocksById<T>() {
  const { state } = useLocation() as { state?: { isUserRedirected: boolean } };
  const { threadId: threadIdQueryParam } = useGetThreadIdFromQueryParam();
  const navigateTo = useNavigate();
  const { isUserAuthenticated } = useAuth();
  const { data: oneThreadData, isLoading: isOneThreadDataLoading } =
    useGetOneThread();

  const {
    data: historyBlocksQueryData,
    isError: isHistoryBlocksError,
    isFetching: isHistoryBlocksFetching,
    isLoading: isHistoryBlocksLoading,
  } = useQuery({
    queryKey: ['historyBlocks', threadIdQueryParam],
    queryFn: async () => {
      try {
        const { data: historyBlocksData } = await threads.getHistoryBlockById({
          id: oneThreadData!.id,
          size: '10',
          order: 'ASC',
          ...(oneThreadData &&
            oneThreadData?.botId && { bot: oneThreadData.botId }),
        });

        return { threadData: oneThreadData, historyBlocksData } as T;
      } catch (error) {
        //? thread not found redirect user to home page
        if (isAxiosError(error) && error.response?.status === 404) {
          navigateTo(paths.home.pathname);
          throw new Error('Thread not found');
        }
        throw new Error(`An error occurred while fetching thread: ${error}`);
      }
    },
    retry: 1,
    enabled:
      isUserAuthenticated &&
      !isOneThreadDataLoading &&
      !state?.isUserRedirected, //? do not fetch if user is redirected since there are not any history blocks yet!
  });

  return {
    historyBlocksQueryData,
    isHistoryBlocksError,
    isHistoryBlocksFetching,
    isHistoryBlocksLoading,
  };
}
