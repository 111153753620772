export const KnowzIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 65 64"
    fill="none"
  >
    <rect
      x="0.89209"
      width="64"
      height="64"
      rx="16"
      fill="url(#paint0_linear_21_695)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.8795 16H45.4332C45.9147 16 46.2413 16.491 46.0553 16.9344L36.3493 40.668C36.2444 40.9183 35.9989 41.0828 35.7271 41.0828H31.2817C30.8026 41.0828 30.476 40.5965 30.6572 40.1531L37.7079 23.1342C37.9058 22.655 37.8438 22.1068 37.5411 21.6825C37.2574 21.2869 36.7998 21.0509 36.3135 21.0509H21.4326C20.8319 21.0509 20.7318 20.3954 20.9559 19.8996C21.3635 18.9914 22.4051 16.1931 22.4051 16.1931C22.5315 16.0691 22.7031 16 22.8795 16ZM43.1209 47.9976H20.5672V48C20.0857 48 19.7591 47.509 19.945 47.0656L29.6511 23.3296C29.756 23.0793 30.0015 22.9149 30.2732 22.9149H34.7187C35.1978 22.9149 35.5243 23.4011 35.3432 23.8445L28.2925 40.8635C28.0946 41.3426 28.1566 41.8908 28.4593 42.3151C28.743 42.7108 29.2006 42.9468 29.6869 42.9468H44.5678C45.1684 42.9468 45.2685 43.6023 45.0445 44.098C44.6369 45.0062 43.5952 47.8046 43.5952 47.8046C43.4689 47.9285 43.2973 47.9976 43.1209 47.9976Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_21_695"
        x1="30.7789"
        y1="-4.28554"
        x2="8.37825"
        y2="67.8522"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5BA1ED" />
        <stop offset="1" stopColor="#3C1AF9" />
      </linearGradient>
    </defs>
  </svg>
);

export const SlackIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.666626" width="24" height="24" rx="6" fill="#4A154B" />
    <rect
      x="3.66663"
      y="3"
      width="18"
      height="18"
      fill="url(#pattern0_1494_28889)"
    />
    <defs>
      <pattern
        id="pattern0_1494_28889"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use transform="translate(-0.000635728) scale(0.00245209)" />
      </pattern>
      <image id="image0_1494_28889" width="1600" height="572" />
    </defs>
  </svg>
);

export const OfficeWordIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <g clip-path="url(#clip0_1524_20699)">
      <path
        d="M2.73325 24H21.9333C23.2587 24 24.3333 22.9255 24.3333 21.6V2.4C24.3333 1.07454 23.2587 0 21.9333 0H2.73325C1.40779 0 0.333252 1.07454 0.333252 2.4V21.6C0.333252 22.9255 1.40779 24 2.73325 24Z"
        fill="#2C59B7"
      />
      <path
        d="M8.50793 15.9873C8.54759 16.3005 8.57333 16.5734 8.58569 16.8067H8.63141C8.64977 16.5856 8.68475 16.319 8.73647 16.0058C8.78819 15.6931 8.83865 15.4292 8.88749 15.2144L11.0079 6H13.75L15.9437 15.0763C16.0535 15.5244 16.1449 16.0947 16.2179 16.7882H16.2544C16.2849 16.3095 16.361 15.7572 16.4829 15.1313L18.238 6H20.7332L17.6622 19.2H14.7462L12.6531 10.4554C12.5922 10.2035 12.5237 9.87558 12.4475 9.47034C12.3713 9.06564 12.324 8.77098 12.3059 8.5869H12.2694C12.245 8.8017 12.1977 9.12066 12.1275 9.5439C12.0574 9.96762 12.0009 10.2803 11.9584 10.4829L9.99323 19.2H7.03181L3.93323 6H6.47417L8.38475 15.2329C8.42729 15.4228 8.46827 15.6746 8.50793 15.9873Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1524_20699">
        <rect x="0.333252" width="24" height="24" rx="6" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const PdfIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <g clip-path="url(#clip0_1524_20971)">
      <path
        d="M2.73325 24H21.9333C23.2587 24 24.3333 22.9255 24.3333 21.6V2.4C24.3333 1.07454 23.2587 0 21.9333 0H2.73325C1.40779 0 0.333252 1.07454 0.333252 2.4V21.6C0.333252 22.9255 1.40779 24 2.73325 24Z"
        fill="#FF2C1F"
      />
      <path
        d="M5.6134 18.2958C4.84262 17.5251 5.67654 16.4659 7.9397 15.3412L9.36371 14.6335L9.91848 13.4197C10.2236 12.7521 10.679 11.6628 10.9305 10.9991L11.3877 9.79221L11.0726 8.89908C10.6852 7.80089 10.547 6.15059 10.7928 5.55717C11.1258 4.75338 12.2163 4.83585 12.6481 5.69747C12.9853 6.37039 12.9508 7.58904 12.5511 9.12594L12.2234 10.3859L12.5121 10.8759C12.6708 11.1454 13.1345 11.7853 13.5425 12.2979L14.3094 13.2518L15.2636 13.1273C18.295 12.7317 19.3333 13.404 19.3333 14.3679C19.3333 15.5845 16.953 15.6849 14.9541 14.281C14.5044 13.9652 14.1955 13.6514 14.1955 13.6514C14.1955 13.6514 12.9434 13.9063 12.3268 14.0725C11.6904 14.244 11.3729 14.3514 10.4407 14.6661C10.4407 14.6661 10.1137 15.1411 9.90053 15.4864C9.10759 16.771 8.18175 17.8357 7.52055 18.2232C6.78025 18.657 6.00415 18.6866 5.6134 18.2958ZM6.82301 17.8637C7.25628 17.5959 8.13322 16.5587 8.74026 15.5961L8.98604 15.2064L7.86695 15.769C6.13852 16.6381 5.3478 17.4571 5.75897 17.9526C5.98995 18.2309 6.26627 18.2078 6.82301 17.8637ZM18.0501 14.7123C18.4739 14.4155 18.4124 13.8174 17.9333 13.5762C17.5604 13.3885 17.2599 13.3499 16.2913 13.3642C15.6961 13.4048 14.739 13.5248 14.5767 13.5613C14.5767 13.5613 15.1025 13.9247 15.336 14.0582C15.6467 14.2357 16.4019 14.5652 16.9533 14.734C17.4971 14.9004 17.8117 14.8829 18.0501 14.7123ZM13.5358 12.8361C13.2794 12.5665 12.8433 12.0038 12.5667 11.5856C12.2051 11.1113 12.0237 10.7767 12.0237 10.7767C12.0237 10.7767 11.7593 11.627 11.5425 12.1386L10.8658 13.8111L10.6697 14.1904C10.6697 14.1904 11.7126 13.8485 12.2432 13.7099C12.8052 13.5631 13.9459 13.3388 13.9459 13.3388L13.5358 12.8361ZM12.0811 7.00205C12.1465 6.45308 12.1742 5.90493 11.9979 5.6285C11.5086 5.09368 10.9181 5.53968 11.0182 6.81119C11.0518 7.23894 11.1581 7.97016 11.3001 8.42085L11.5584 9.24028L11.7402 8.62311C11.8402 8.28367 11.9936 7.55419 12.0811 7.00205Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1524_20971">
        <rect x="0.333252" width="24" height="24" rx="6" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
