import PhotosBlock from '@components/pages/search/components/shared-blocks/photos';
import { WSResponseType } from '@state/middleware/types';
import { RootType } from '@state/store';
import { useSelector } from 'react-redux';

export default function StreamingPhotos() {
  const searchResult = useSelector<RootType, WSResponseType>(
    (state) => state.websocket.WSResponse,
  );

  if (searchResult == null) return null;

  const photosData = searchResult.searchResult.layout?.find(
    (block) => block?.type === 'photos',
  );
  const infoData = searchResult.searchResult.layout?.find(
    (block) => block?.type === 'info',
  );
  const answerData = searchResult.searchResult.layout?.find(
    (block) => block?.type === 'answer',
  );

  return (
    photosData &&
    photosData.photos.length > 0 && (
      <PhotosBlock
        isThereAnswer={answerData == null ? false : true}
        isThereInfo={infoData == null ? false : true}
        photosData={photosData}
      />
    )
  );
}
