/* eslint-disable import/prefer-default-export */
import { styled } from '@mui/material';
import Paper from '@mui/material/Paper';
import MuiTableCell from '@mui/material/TableCell';

export const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: 0,
  width: '100%',
  overflow: 'auto',
  borderRadius: `${theme.spacing(4, 4, 0, 0)}!important`,
}));

export const StyledMuiTableHeadCell = styled(MuiTableCell)(({ theme }) => ({
  position: 'relative',
  '&:not(:last-child)::after': {
    content: '""',
    width: theme.spacing(0.25),
    height: theme.spacing(5.5),
    backgroundColor: theme.palette.divider,
    display: 'block',
    position: 'absolute',
    left: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));
