import StyledMarkdown from '@components/markdown/styles';
import { SxProps } from '@mui/material';
import { Options as MarkdownOptions } from 'react-markdown';

export default function Markdown({
  children,
  sx,
  ...reset
}: React.PropsWithChildren<MarkdownOptions & { sx?: SxProps }>) {
  return (
    <StyledMarkdown
      sx={sx}
      components={{
        a(props) {
          const { children, node, ...rest } = props;
          return (
            <a {...rest} target="_blank" rel="noopener noreferrer">
              {children}
            </a>
          );
        },
      }}
      {...reset}
    >
      {children?.toString()}
    </StyledMarkdown>
  );
}
