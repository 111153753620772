import { templates } from '@lib/agent';
import { TemplateType } from '@shared-types/desygner';
import { useQuery, queryOptions } from '@tanstack/react-query';
import AsyncAutocomplete, { OptionType } from '@components/pages/command-designer/entity-selectors/AsyncAutocomplete';

const DEFAULT_WORKSPACE_ID = String(import.meta.env.VITE_WORKSPACE_ID);

export interface TemplateProps {
  value: number|null;
  onChange: (value: TemplateType|null) => void;
}

export default function Template(props: TemplateProps) {
  const options = queryOptions({
    queryKey: ['templates'],
    queryFn: () => templates.getAll({ workspaceId: DEFAULT_WORKSPACE_ID }),
  });

  const { data, isLoading } = useQuery(options);

  return <AsyncAutocomplete
    options={(data?.data||[]) as OptionType[]}
    isLoading={isLoading}
    placeholder="Select a template"
    {...props}
  ></AsyncAutocomplete>
}
