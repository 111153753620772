//TODO: there are some static CSS rule sets that need to be dynamic.
import useGetHistoryBlocksById from '@components/pages/hooks/useGetHistoryBlocksById';
import AnswerBlock from '@components/pages/search/components/shared-blocks/answer';
import FollowupsBlock from '@components/pages/search/components/shared-blocks/followups';
import InfoBlock from '@components/pages/search/components/shared-blocks/info';
import Layout from '@components/pages/search/components/shared-blocks/layout';
import LinksBlock from '@components/pages/search/components/shared-blocks/links';
import PhotosBlock from '@components/pages/search/components/shared-blocks/photos';
import PromptBlock from '@components/pages/search/components/shared-blocks/prompt';
import SinglePhotosCol from '@components/pages/search/components/shared-blocks/single-photos-col';
import TermsOfService from '@components/pages/search/components/shared-blocks/tos';
import LoadingSkeleton from '@components/pages/search/sections/loading-skeleton';

export default function HistoryBlocks() {
  const { historyBlocksQueryData, isHistoryBlocksLoading } =
    useGetHistoryBlocksById();

  if (isHistoryBlocksLoading) {
    return <LoadingSkeleton />;
  }

  return historyBlocksQueryData?.historyBlocksData.map(
    (item, i: number, originalArray: []) => {
      if (item.role === 'user') return null;
      if (item.tos != null) {
        return <TermsOfService tos={item.tos} />;
      }

      const infoData = item?.layout?.info;
      const isThereAnswer =
        item?.layout?.answer?.response == null ? false : true;
      const isThereInfo =
        infoData.length === 0 || infoData[0].response == null ? false : true;

      return (
        <Layout
          key={i}
          answer={<AnswerBlock answer={item?.layout?.answer?.response} />}
          info={<InfoBlock text={infoData[0]?.response} />}
          followups={
            originalArray.length - 1 === i ? (
              <FollowupsBlock followups={item.followups} />
            ) : undefined
          }
          prompt={<PromptBlock prompt={item.layout.userQuery} />}
          photos={
            <PhotosBlock
              isThereAnswer={isThereAnswer}
              isThereInfo={isThereInfo}
              photosData={item?.layout?.photos}
            />
          }
          links={<LinksBlock links={item?.layout?.links} />}
          singlePhotosCol={
            <SinglePhotosCol
              isThereAnswer={isThereAnswer}
              isThereInfo={isThereInfo}
              photosData={item?.layout?.photos}
            />
          }
        />
      );
    },
  );
}
