import { KnowzStepDefinition } from '@lib/step/types';

const DynamicSwitchStepDefinition: KnowzStepDefinition = {
  order: 301,
  group: 'Logic',
  step: {
    componentType: 'switch',
    type: 'dynamic-switch',
    name: 'Path fork',
    branches: { true: [], false: [] },
  },
  propertyTypes: [
    {
      id: 'input',
      name: 'Case',
      description:
        'Select one of your previous outputs to determine the path to follow',
      usage: 'input',
      wrapper: 'item',
      types: ['ref-number', 'ref-numbers', 'ref-string', 'ref-strings', 'ref-match', 'ref-matches'],
    },
    {
      id: 'branches',
      name: 'Cases',
      description: 'Define a list of possible cases',
      usage: 'definition',
      wrapper: 'list',
      types: ['string'],
    },
    {
      id: 'defaultBranch',
      name: 'Default case',
      description: 'Define a default case when none matches',
      usage: 'definition',
      wrapper: 'item',
      types: ['string'],
    },
  ],
};

export default DynamicSwitchStepDefinition;
