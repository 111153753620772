import Markdown from '@components/markdown';
import Card from '@mui/material/Card';

type Props = {
  text: string;
};

export default function Info({ text }: Props) {
  if (text == null) return;

  return (
    <Card>
      <Markdown>{text}</Markdown>
    </Card>
  );
}
