import AutocompleteWithCarousel from '@components/autocomplete-with-carousel';
import EllipsisTypography from '@components/ellipsis-typography';
import CONFIG from '@components/pages/side-kick/config';
import {
  StyledListItem,
  StyledListItemImage,
  StyledListItemStack,
  StyledStack,
} from '@components/pages/side-kick/styles';
import { AutocompleteValueOnChangeType } from '@components/pages/side-kick/types';
import {
  StyledCenteredContent,
  StyledViewportHeightStack,
} from '@components/pages/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import useCreateOneThread from '@hooks/useCreateOneThread';
import usePermission from '@hooks/useNavigatorPermissions';
import useResponsive from '@hooks/useResponsive';
import useTimer from '@hooks/useTimer';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import LoadingButton from '@mui/lab/LoadingButton';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { queryClient } from '@providers/ReactQueryProvider';
import {
  isRecordingUpdated,
  shouldRunFactCheckUpdated,
  shouldRunSentimentCheckUpdated,
} from '@state/slices/transcription';
import { RootType } from '@state/store';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const MODE_IMAGE_WIDTH = 194;
const MODE_IMAGE_HEIGHT = 60;

const SIDE_KICK_IMAGE_WIDTH = 230;
const SIDE_KICK_IMAGE_HEIGHT = 93;

export default function SideKick() {
  const { t } = useTranslation();
  const isItMobileOrTablet = useResponsive('down', 'md');
  const navigateTo = useNavigate();
  const {
    mutateAsync: mutateCreateOneThreadAsync,
    isPending: isCreateOneThreadPending,
  } = useCreateOneThread();
  const dispatch = useDispatch();
  const timer = useTimer();
  const [isSideKickSessionStarting, setIsSideKickSessionStaring] =
    useState(false);
  const shouldRunFactCheck = useSelector(
    (state: RootType) => state.transcription.shouldRunFactCheck,
  );
  const shouldRunSentimentCheck = useSelector(
    (state: RootType) => state.transcription.shouldRunSentimentCheck,
  );
  const [trainingSets, setTrainingSets] = useState([
    ...(CONFIG.SIDE_KICK_MODES[0].cats ?? []),
  ]);

  // //TODO: useGeolocation should be handled like this with useNavigatorPermissions that I wrote
  const {
    permission: { state: permissionState, isLoading: isPermissionLoading },
    requestMicrophone,
  } = usePermission('microphone');

  useEffect(() => {
    if (permissionState === 'granted' && isSideKickSessionStarting) {
      (async function () {
        const name = new Date().toISOString();
        try {
          const { data } = await mutateCreateOneThreadAsync({
            name,
            type: 'live',
            trainingSets,
          });

          navigateTo(`/side-kick/session?t=${data.id}`);
        } catch (error) {
          console.error(error);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionState, isSideKickSessionStarting]);

  async function onClickHandler() {
    if (permissionState === 'denied') {
      toast.info(
        t('page.sideKickSession.mic.denied', {
          defaultValue: 'Your mic access denied please allow it first',
        }),
        { autoClose: false },
      );
      return;
    }
    requestMicrophone();
    dispatch(isRecordingUpdated(true));
    timer.startTimer();
    setIsSideKickSessionStaring(true);
  }

  function handleTrainingSets(
    _: React.SyntheticEvent,
    value: AutocompleteValueOnChangeType,
  ) {
    const arrayOfTrainingSets = value
      .filter((v) => v.type === 'training-sets')
      .map((v) => v.cats)
      .flat();
    const shouldRunFactCheck = value.some((v) => v.type === 'fact-check');

    dispatch(shouldRunFactCheckUpdated(shouldRunFactCheck));
    dispatch(shouldRunSentimentCheckUpdated(arrayOfTrainingSets.length > 0));

    setTrainingSets(arrayOfTrainingSets as string[]);
  }

  return (
    <Container maxWidth="lg">
      <StyledViewportHeightStack>
        <StyledCenteredContent gap={8}>
          <img
            src="https://assets.knowz.com/assets/svg/side-kick.svg"
            alt="knowz live"
            width={SIDE_KICK_IMAGE_WIDTH}
            height={SIDE_KICK_IMAGE_HEIGHT}
          />
          <Typography component="h1" variant="h3">
            {t('page.sideKick.hero.title', {
              defaultValue: 'Need LIVE help now?',
            })}
          </Typography>
          <LoadingButton
            startIcon={<GraphicEqIcon />}
            variant="contained"
            size={isItMobileOrTablet ? 'medium' : 'large'}
            onClick={onClickHandler}
            loading={isCreateOneThreadPending || isPermissionLoading}
            disabled={
              shouldRunSentimentCheck === false && shouldRunFactCheck === false
            }
          >
            {t('page.sideKick.hero.button', {
              defaultValue: 'Start Smart Recording',
            })}
          </LoadingButton>
          <StyledStack gap={5}>
            <Typography variant="h4">
              {t('page.sideKick.modes.title', {
                defaultValue: 'Choose your mode',
              })}
            </Typography>
            <AutocompleteWithCarousel
              multiple
              defaultValue={[CONFIG.SIDE_KICK_MODES[0], CONFIG.FACT_CHECK[0]]}
              ChipProps={{
                size: isItMobileOrTablet ? 'small' : 'medium',
              }}
              options={[...CONFIG.SIDE_KICK_MODES, ...CONFIG.FACT_CHECK]}
              onChange={handleTrainingSets}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    type="text"
                    size={isItMobileOrTablet ? 'small' : 'medium'}
                    variant="outlined"
                    autoComplete="off"
                    placeholder={t('page.sideKick.modes.placeholder', {
                      defaultValue: 'Search for a mode...',
                    })}
                  />
                );
              }}
              renderOption={(props, option, { selected }) => {
                const { key, ...otherProps } =
                  props as React.HTMLAttributes<HTMLLIElement> & {
                    key: string;
                  };
                return (
                  <StyledListItem
                    {...otherProps}
                    key={key}
                    components={{ Root: 'div' }}
                    sx={{
                      borderColor: selected
                        ? 'primary.light'
                        : 'background.secondary',
                    }}
                  >
                    <StyledListItemStack gap={2}>
                      <StyledListItemImage
                        src={option.image}
                        alt={option.title}
                        loading="lazy"
                        width={MODE_IMAGE_WIDTH}
                        height={MODE_IMAGE_HEIGHT}
                      />
                      <EllipsisTypography
                        variant="subtitle2"
                        component="h2"
                        lineClamp={2}
                        title={option.title}
                      >
                        {option.title}
                      </EllipsisTypography>
                      <EllipsisTypography
                        variant="caption"
                        color="text.disabled"
                        lineClamp={2}
                        title={option.description}
                      >
                        {option.description}
                      </EllipsisTypography>
                    </StyledListItemStack>
                  </StyledListItem>
                );
              }}
              getOptionLabel={(option) => option.title}
              noOptionsText={t('page.sideKick.modes.noOptions', {
                defaultValue: 'No modes found',
              })}
            />
          </StyledStack>
        </StyledCenteredContent>
      </StyledViewportHeightStack>
    </Container>
  );
}
