//TODO: maybe using qs package to handle query string
import requests from '@lib/agent/requests';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';
import type {
  AIMatchBodyType,
  AIMatchResponseType,
  CreateAIThreadType,
} from '@shared-types/auth';
import {
  AskAIType,
  FactCheckBodyType,
  FactCheckResponseType,
  GetAllThreadsParamsType,
  GetAllThreadsResponseType,
} from '@shared-types/threads';

export const threads = {
  createOneThread: (body: CreateAIThreadType) =>
    requests(axiosApiInstance).post<{ id: number; name: string }>(
      '/v1/entities/threads',
      body,
    ),
  askAI: (body: AskAIType) =>
    requests(axiosApiInstance).post('/v1/actions/ai/ask', body),
  getThreadById: (id: string) =>
    requests(axiosApiInstance).get<{
      name: string;
      id: string;
      botId?: number;
    }>(`/v1/entities/threads/${id}`),
  getHistoryBlockById: <T>({
    id,
    size,
    order,
  }: {
    id: string;
    size: string;
    order: 'ASC' | 'DESC';
  }) =>
    requests(axiosApiInstance).get<T>(
      `/v1/entities/threads/${id}/history-blocks?size=${size}&order=${order}`,
    ),
  getAIMatch: (body: AIMatchBodyType) =>
    requests(axiosApiInstance).post<AIMatchResponseType>(
      '/v1/actions/ai/match',
      body,
    ),
  getAll: ({ limit, order, page, type }: GetAllThreadsParamsType) =>
    requests(axiosApiInstance).get<GetAllThreadsResponseType[]>(
      `/v1/entities/threads?page=${page}&limit=${limit}&order=${order}&type=${type}`,
    ),
  updateOneById: (id: string, body: { name: string }) =>
    requests(axiosApiInstance).patch(`/entities/threads/${id}`, body),
  getFactCheck: (body: FactCheckBodyType) =>
    requests(axiosApiInstance).post<FactCheckResponseType>(
      '/v1/actions/ai/fact',
      body,
    ),
};

export default threads;
