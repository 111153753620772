import StyledButton from '@components/pages/side-kick-session/sections/stop-recording/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import useTranscriptionService from '@hooks/useTranscriptionService';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import { useConfirm } from 'material-ui-confirm';
import { useNavigate } from 'react-router-dom';

export default function StopRecordingButton() {
  const { stopTranscript } = useTranscriptionService();
  const { t } = useTranslation();
  const confirm = useConfirm();
  const navigateTo = useNavigate();

  async function handleStopRecording() {
    confirm({
      title: t('page.sideKickSession.confirmStopRecording.title', {
        defaultValue: 'Stop Recording Session',
      }),
      description: t('page.sideKickSession.confirmStopRecording.content', {
        defaultValue: 'Are you sure you want to stop recording?',
      }),
      confirmationText: t('page.sideKickSession.confirmStopRecording.yes', {
        defaultValue: 'Yes, I want to stop recording',
      }),
      cancellationText: t('page.sideKickSession.confirmStopRecording.no', {
        defaultValue: 'No, I want to continue',
      }),
    })
      .then(() => {
        stopTranscript();
        navigateTo('/side-kick');
      })
      .catch(() => null);
  }

  return (
    <StyledButton
      color="error"
      variant="outlined"
      startIcon={<AdjustOutlinedIcon />}
      onClick={handleStopRecording}
    >
      {t('page.sideKickSession.stopRecording', {
        defaultValue: 'Stop Recording',
      })}
    </StyledButton>
  );
}
