import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { useTranslation } from '@desygner/ui-common-translation';

type Props = {
  img: React.ReactNode;
};

export default function ErrorTemplate({ img }: Props) {
  const { t } = useTranslation();
  return (
    <Container maxWidth="md">
      <Stack
        gap={6}
        sx={{ alignItems: 'center', justifyContent: 'center', height: '100vh' }}
      >
        {img}
        <Stack sx={{ alignItems: 'center' }}>
          <Typography variant="h3" component="h1" sx={{ textAlign: 'center' }}>
            {t('page.error.title', {
              defaultValue: "Uh oh!  There's a glitch in the matrix.",
            })}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: 'neutral.grey', textAlign: 'center' }}
          >
            {t('page.error.subtitle', {
              defaultValue:
                "We're working to fix it. Check back soon for everything to be back to normal.",
            })}
          </Typography>
        </Stack>
        <Button
          LinkComponent={Link}
          href={import.meta.env.VITE_KNOWZ_WEBSITE_URL}
          variant="contained"
        >
          {t('page.error.cta', {
            defaultValue: 'Back to Home',
          })}
        </Button>
      </Stack>
    </Container>
  );
}
