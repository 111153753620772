import paths from '@router/paths';
import { StyledLink, StyledLogo } from './styles';

type Props = {
  isLink?: boolean;
  variant?: 'mini' | 'full';
};

const MINI_LOGO_SIZE = 45;

const FULL_LOGO_WIDTH_SIZE = 100;
const FULL_LOGO_HEIGHT_SIZE = 25;

export default function Logo({ variant, isLink = false }: Props) {
  const isMini = variant === 'mini';

  let logo;

  if (isMini) {
    logo = (
      <StyledLogo
        width={MINI_LOGO_SIZE}
        height={MINI_LOGO_SIZE}
        alt="knowz"
        src="https://assets.knowz.com/assets/svg/knowz-favicon---gradient.svg"
      />
    );
  }

  if (!isMini) {
    logo = (
      <StyledLogo
        width={FULL_LOGO_WIDTH_SIZE}
        height={FULL_LOGO_HEIGHT_SIZE}
        alt="knowz"
        src="https://assets.knowz.com/assets/svg/knowz---solid.svg"
      />
    );
  }

  return isLink ? (
    <StyledLink to={paths.home.pathname}>{logo}</StyledLink>
  ) : (
    logo
  );
}
