import FormProvider from '@components/react-hook-form/FormProvider';
import RHFTextField from '@components/react-hook-form/RHFTextField';
import FormHeading from '@components/user-authentication/components/form-heading';
import useLookupUserEmail from '@components/user-authentication/hooks/useLookupEmail';
import useUserAuth from '@components/user-authentication/hooks/useUserAuth';
import { enterEmailSchema } from '@components/user-authentication/schemas';
import { useTranslation } from '@desygner/ui-common-translation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEnabledAccessConfigurations } from '@hooks/useAccessConfigurations';
import useAuth from '@hooks/useAuth';
import useResponsive from '@hooks/useResponsive';
import useSearchParamsState from '@hooks/useSearchParamsState';
import { getDecodedToken } from '@lib/jwt';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AccessConfigurationMinType } from '@shared-types/sso';
import { isAxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import serviceBranding from '@lib/auth/serviceBranding';
import { useCallback, useEffect, useState } from 'react';
import { setSession } from '@lib/jwt';

type EnterEmailFormType = {
  email: string;
};

export default function EnterEmail() {
  const { t } = useTranslation();
  const isItMobileOrTablet = useResponsive('down', 'md');
  const {
    handleIsUserAlreadyRegistered,
    handleSetEmail,
    handleNextStep,
    handleAuthenticationJourney,
    resetForm,
  } = useUserAuth();
  const { accessConfigurationsData, isLoadingAccessConfigurations } =
    useEnabledAccessConfigurations();
  const [, setSearchParams] = useSearchParamsState('action', 'secure-account');
  const { attemptedToLogin, handleAttemptedToLogin, handleUpdateMe } =
    useAuth();
  const token = window.localStorage.getItem('token');
  const decodedToken = token ? getDecodedToken(token) : null;

  const methods = useForm<EnterEmailFormType>({
    resolver: yupResolver(enterEmailSchema),
    defaultValues: {
      email:
        decodedToken != null &&
        decodedToken.auth_type === 'email' &&
        !attemptedToLogin
          ? decodedToken.email
          : '',
    },
  });

  const closeModal = useCallback(() => {
    setSearchParams('');
    resetForm();
    handleAttemptedToLogin(true);
  }, [handleAttemptedToLogin, resetForm, setSearchParams]);

  const [ssoWindow, setSsoWindow] = useState<Window | null>(null);

  const onMessage = useCallback(
    (event: MessageEvent) => {
      const { accessJwt, accessError } = event.data;
      if (!accessJwt && !accessError) return;

      ssoWindow && ssoWindow.close();
      window.removeEventListener('message', onMessage);
      setSsoWindow(null);

      if (accessError) return toast.error(accessError);
      setSession(accessJwt);
      handleUpdateMe(accessJwt);
      closeModal();
    },
    [ssoWindow, handleUpdateMe, closeModal],
  );

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  const onSsoLogin = useCallback(
    (alias: string) => {
      const auth = import.meta.env.VITE_KNOWZ_AUTH_URL || '';

      ssoWindow && ssoWindow.close();
      setSsoWindow(
        window.open(
          `${auth}/connect/oauth2?alias=${alias}&process=access`,
          'Knowz Authentication',
          'menubar=1,resizable=1,width=900,height=550',
        ),
      );
    },
    [ssoWindow],
  );

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
  } = methods;

  const {
    mutateAsync: lookupUserEmailAsync,
    isPending: isLookupUserEmailPending,
  } = useLookupUserEmail();

  const isBusy = isSubmitting || isLookupUserEmailPending;

  const isThereAnySSOConfig =
    !isLoadingAccessConfigurations &&
    accessConfigurationsData &&
    accessConfigurationsData.data.length > 0;

  async function onSubmit(data: EnterEmailFormType) {
    try {
      const lookupEmailResponse = await lookupUserEmailAsync(data);
      if (
        lookupEmailResponse.status === 226 &&
        lookupEmailResponse.data.status === 'Im Used'
      ) {
        handleSetEmail(data.email);
        handleIsUserAlreadyRegistered(true);
        handleNextStep();
        handleAuthenticationJourney('login');
        return;
      }
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 404) {
        handleSetEmail(data.email);
        handleIsUserAlreadyRegistered(false);
        handleNextStep();
        handleAuthenticationJourney('register');
        return;
      }
      toast.error(
        t('response.errors.code.520', {
          defaultValue: 'Something went wrong. Please try again later.',
        }),
      );
    }
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2} direction="row" sx={{ alignItems: 'center' }}>
        {isItMobileOrTablet && (
          <IconButton
            sx={{
              paddingTop: 12,
              paddingBottom: 8,
            }}
            onClick={closeModal}
          >
            <ArrowBackIosIcon />
          </IconButton>
        )}
        <FormHeading
          heading={t('layout.auth.desktop.modal.steps.enterEmail.label', {
            defaultValue: 'Get started with email',
          })}
        />
      </Stack>
      <Stack gap={5}>
        <Typography variant="body2">
          {t('layout.auth.desktop.modal.steps.enterEmail.description', {
            defaultValue:
              'We’ll check if you have an account, and help create one if you don’t.',
          })}
        </Typography>
        <RHFTextField
          type="email"
          size="small"
          disabled={isBusy}
          placeholder={t(
            'layout.auth.desktop.modal.steps.enterEmail.form.email.placeholder',
            {
              defaultValue: 'email',
            },
          )}
          fullWidth
          name="email"
          autoComplete="username" //? https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands/
          error={!!errors.email?.message}
          helperText={errors.email?.message}
        />
      </Stack>
      <Stack gap={2} sx={{ mt: 5 }}>
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          disabled={isBusy} //TODO: just remove isDirty since it does not work with defaultValue properly later we find a workaround
          loading={isBusy}
          variant="contained"
          fullWidth
        >
          {t('layout.auth.desktop.modal.steps.enterEmail.form.button', {
            defaultValue: 'Continue',
          })}
        </LoadingButton>

        {isThereAnySSOConfig && (
          <>
            <Typography variant="body2" align="center">
              {t('layout.auth.desktop.modal.steps.enterEmail.form.or', {
                defaultValue: 'or',
              })}
            </Typography>
            <Stack direction="row" gap={2}>
              {accessConfigurationsData.data.map(
                ({ provider, alias, name }: AccessConfigurationMinType) => (
                  <LoadingButton
                    key={name}
                    onClick={() => onSsoLogin(alias)}
                    variant="contained"
                    fullWidth
                  >
                    <Stack gap={2} direction="row">
                      <img
                        src={serviceBranding[provider || 'custom'].icon}
                        alt={name}
                        loading="lazy"
                        height={20}
                      />
                      <span>Login with {name}</span>
                    </Stack>
                  </LoadingButton>
                ),
              )}
            </Stack>
          </>
        )}
      </Stack>
    </FormProvider>
  );
}
