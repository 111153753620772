import { StyledContainerForFixedHeader } from '@components/pages/styles';
import Paper from '@mui/material/Paper';
import FormGroup from '@mui/material/FormGroup';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  OAuthOptionType,
  ProviderType,
  OAUTH_FIELDS,
} from '@components/pages/sso/oauth-fields';
import { StyledAccordion } from '@components/pages/sso/styles';
import Provider, { titleCase } from '@components/pages/sso/components/Provider';
import { useCallback, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import { useForm } from 'react-hook-form';
import FormField from '@components/pages/sso/components/FormField';
import FormProvider from '@components/react-hook-form/FormProvider';
import {
  OAuthConfigType,
  PathsType,
  AccessConfigurationType,
} from '@shared-types/sso';
import {
  useGetAccessConfiguration,
  useUpdateOneAccessConfiguration,
} from '@hooks/useAccessConfigurations';
import { useParams } from 'react-router-dom';
import { queryOptions, useQuery } from '@tanstack/react-query';
import axios from 'axios';

type FieldType = AccessConfigurationOptionType | OAuthOptionType | UIOptionType;

type AccessConfigurationOptionType = {
  type: 'accessConfiguration';
  label: string;
  key: 'name' | 'alias' | 'isSample' | 'isRootDefault';
  element: 'text' | 'checkbox';
  sysAdmin?: boolean;
};

type UIOptionType = {
  type: 'ui';
  element: 'divider';
};

const ALL_FIELDS: FieldType[] = [
  {
    type: 'accessConfiguration',
    label: 'Name',
    key: 'name',
    element: 'text',
  },
  {
    type: 'accessConfiguration',
    label: 'Is Sample',
    key: 'isSample',
    element: 'checkbox',
    sysAdmin: true,
  },
  {
    type: 'accessConfiguration',
    label: 'Is Default',
    key: 'isRootDefault',
    element: 'checkbox',
    sysAdmin: true,
  },
  { type: 'ui', element: 'divider' },
  ...OAUTH_FIELDS,
];

const EMPTY_PATHS: PathsType = {
  identifier: '',
  nickname: undefined,
  realname: undefined,
  email: undefined,
  firstname: undefined,
  lastname: undefined,
};

const EMPTY_OAUTH_CONFIGURATION: OAuthConfigType = {
  clientId: '',
  clientSecret: '',
  authorizationUrl: '',
  accessTokenUrl: '',
  infosUrl: '',
  csrf: undefined,
  authWithOneUrl: undefined,
  useCommasInScope: undefined,
  useAuthorizationToGetToken: undefined,
  refreshOnExpire: undefined,
  sandbox: undefined,
  includeEmail: undefined,
  appsecretProof: undefined,
  useBearerAuthorization: undefined,
  attrName: undefined,
  realm: undefined,
  requestTokenUrl: undefined,
  revokeTokenUrl: undefined,
  scope: undefined,
  application: undefined,
  scopes: undefined,
  signatureMethod: undefined,
  duration: undefined,
  perms: undefined,
  userResourceUrl: undefined,
  hd: undefined,
  requestVisibleActions: undefined,
  accessType: undefined,
  approvalPrompt: undefined,
  display: undefined,
  loginHint: undefined,
  prompt: undefined,
  emailsUrl: undefined,
  responseType: undefined,
  applicationKey: undefined,
  fields: undefined,
  meUrl: undefined,
  emailUrl: undefined,
  authType: undefined,
  responseMode: undefined,
  authKey: undefined,
  keyId: undefined,
  teamId: undefined,
  format: undefined,
  apiVersion: undefined,
  site: undefined,
  protocol: undefined,
  version: undefined,
  infosSessionUrl: undefined,
  paths: EMPTY_PATHS,
};

const EMPTY_ACCESS_CONFIGURATION: AccessConfigurationType = {
  id: -1,
  name: '',
  alias: '',
  provider: '',
  isSample: false,
  isRootDefault: false,
  config: EMPTY_OAUTH_CONFIGURATION,
};

export interface SsoProps {
  mode: 'sysAdmin' | 'user';
}

export default function Sso({ mode }: SsoProps) {
  const { id } = useParams();
  const [displayAdvanced, setDisplayAdvanced] = useState(false);
  const [displayHidden, setDisplayHidden] = useState(false);
  const [provider, setProvider] = useState<ProviderType | null>(null);
  const { accessConfigurationData, isLoadingAccessConfiguration } =
    useGetAccessConfiguration(parseInt(id!));
  const { mutateAsync: mutateUpdateOneAccessConfigurationAsync } =
    useUpdateOneAccessConfiguration();

  const options = queryOptions({
    queryKey: ['sso-providers'],
    queryFn: async () => {
      try {
        const res = await axios.get(
          'https://assets.knowz.com/config/oauth.json',
        );
        return res.data;
      } catch (error) {
        console.error('Error while fetching SSO providers from CDN: ', error);
      }
    },
  });

  const { data: providersData, isLoading: isLoadingProviders } =
    useQuery(options);

  const formMethods = useForm<AccessConfigurationType>();
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
    getValues,
  } = formMethods;

  const [mainFields, setMainFields] = useState(
    ALL_FIELDS.filter(
      (f) =>
        (!f.sysAdmin || mode === 'sysAdmin') &&
        (f.type !== 'oauthConfiguration' || (!f.advanced && !f.hidden)),
    ),
  );

  const [advancedFields, setAdvancedFields] = useState(
    ALL_FIELDS.filter(
      (f) => f.type !== 'oauthConfiguration' || (f.advanced && !f.hidden),
    ),
  );

  const onProviderSelection = useCallback(
    (p: ProviderType | null) => {
      const { isSample, isRootDefault, provider } = getValues();

      if (!p || provider !== p.id) {
        const values = {
          ...EMPTY_ACCESS_CONFIGURATION,
          config: {
            ...EMPTY_OAUTH_CONFIGURATION,
            paths: { ...EMPTY_PATHS },
          },
        };

        if (p) {
          const options = p.options || {};
          values.provider = p.id || '';
          values.name = titleCase(values.provider);
          Object.keys(options).forEach((key) => {
            values.config[key] = options[key];
          });

          const paths = p.paths || {};
          Object.keys(paths).forEach((key) => {
            values.config.paths[key] = paths[key];
          });
        }

        if (accessConfigurationData?.data) {
          values.id = accessConfigurationData.data.id;
          values.alias = accessConfigurationData.data.alias;
          values.isSample = isSample;
          values.isRootDefault = isRootDefault;
        }

        reset(values);
      }

      setDisplayHidden(false);
      setDisplayAdvanced(false);
      setProvider(p);
    },
    [reset, getValues, accessConfigurationData],
  );

  useEffect(() => {
    if (accessConfigurationData?.data && providersData) {
      const { data } = accessConfigurationData;
      const values = {
        ...EMPTY_ACCESS_CONFIGURATION,
        ...data,

        config: {
          ...EMPTY_OAUTH_CONFIGURATION,
          ...data.config,

          paths: {
            ...EMPTY_PATHS,
            ...data.config.paths,
          },
        },
      };

      reset(values);
      const id = data.provider;
      const provider = providersData[id];
      provider && onProviderSelection({ id, ...provider });
    }
  }, [reset, accessConfigurationData, providersData, onProviderSelection]);

  useEffect(() => {
    const options = provider?.options || {};
    const paths = provider?.paths || {};
    const filterDefault = (expectedAdvancedValue: boolean, f) => {
      const displayDefaultsAsAdvanced = !expectedAdvancedValue;

      if (f.type === 'oauthConfiguration') {
        const isPreDefined =
          (f.key.indexOf('config.paths.') === 0 &&
            Object.keys(paths).includes(
              f.key.replace(/^config.paths\./, ''),
            )) ||
          (f.key.indexOf('config.') === 0 &&
            Object.keys(options).includes(f.key.replace(/^config\./, '')));

        if (displayDefaultsAsAdvanced && isPreDefined) return true;
        if (f.advanced === expectedAdvancedValue) return false;
        if (displayHidden) return true;
        if (f.hidden) return false;
        if (isPreDefined) return false;

        const isDefault = provider && !f.required;
        if (isDefault) return false;
      } else {
        if (
          f.type === 'accessConfiguration' &&
          f.sysAdmin &&
          mode !== 'sysAdmin'
        ) {
          return false;
        }

        if (f.type === 'accessConfiguration' || f.type === 'ui') {
          return expectedAdvancedValue;
        }
      }

      return true;
    };

    setMainFields(ALL_FIELDS.filter(filterDefault.bind(null, true)));
    setAdvancedFields(ALL_FIELDS.filter(filterDefault.bind(null, false)));
  }, [provider, displayHidden, mode]);

  async function onSubmit(data: AccessConfigurationType) {
    mutateUpdateOneAccessConfigurationAsync(data);
  }

  if (isLoadingAccessConfiguration || isLoadingProviders) {
    return (
      <LinearProgress
        sx={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          width: '50%',
          maxWidth: '400px',
          transform: 'translate(-50%, -50%)',
        }}
      />
    );
  }

  return (
    <StyledContainerForFixedHeader maxWidth="sm">
      <Paper sx={{ backgroundColor: 'background.card.light' }}>
        <Stack
          sx={{ mb: 2 }}
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
        >
          <Typography variant="h4">Single Sign On configuration</Typography>

          <Tooltip
            title={
              displayHidden
                ? 'hide detailed configuration'
                : 'show detailed configuration'
            }
            placement="left"
            arrow
          >
            <IconButton
              onClick={() => {
                const newDisplayHidden = !displayHidden;
                setDisplayAdvanced(newDisplayHidden);
                setDisplayHidden(newDisplayHidden);
              }}
            >
              {displayHidden ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </Tooltip>
        </Stack>

        <Provider
          data={providersData}
          value={provider ? provider.id! : null}
          onChange={onProviderSelection}
        />

        <FormProvider methods={formMethods} onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            {mainFields.map((field) =>
              field.element === 'divider' ? (
                <Divider key={'divider'} />
              ) : (
                <FormField
                  element={field.element}
                  key={field.key}
                  name={field.key}
                  label={field.label}
                />
              ),
            )}
          </FormGroup>

          {/* TODO: readonly fields for callback URL and /connect */}

          {!!advancedFields.length && (
            <StyledAccordion sx={{ mt: 2 }} expanded={displayAdvanced}>
              <AccordionSummary
                onClick={() => setDisplayAdvanced(!displayAdvanced)}
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Advanced Configuration
              </AccordionSummary>

              <AccordionDetails>
                <FormGroup>
                  {advancedFields.map(
                    (field) =>
                      field.element !== 'divider' && (
                        <FormField
                          element={field.element}
                          key={field.key}
                          name={field.key}
                          label={field.label}
                        />
                      ),
                  )}
                </FormGroup>
              </AccordionDetails>
            </StyledAccordion>
          )}

          <Button
            fullWidth
            sx={{ mt: 3 }}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </FormProvider>
      </Paper>
    </StyledContainerForFixedHeader>
  );
}
