//TODO: for answers and infos coming from websocket we have answer.text and answer.info for history blocks we have answer.answer and info.answer we need to lay out head one side.
import Markdown from '@components/markdown';
import SectionHeading from '@components/pages/search/components/SectionHeading';
import { useTranslation } from '@desygner/ui-common-translation';
import Icon from '@mui/material/Icon';

type Props = {
  answer?: string;
};

const ORB_ICON_ELEMENT = (
  <Icon>
    <img
      src="https://assets.knowz.com/assets/svg/orb---small.png"
      alt="answer section"
    />
  </Icon>
);

export default function Answer({ answer }: Props) {
  const { t } = useTranslation();
  if (answer == null) return null;

  return (
    <SectionHeading
      title={t('page.search.sections.answer', {
        defaultValue: 'Answer',
      })}
      icon={ORB_ICON_ELEMENT}
    >
      <Markdown>{answer}</Markdown>
    </SectionHeading>
  );
}
