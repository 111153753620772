import assistants from '@lib/agent/endpoints/assistants';
import { useQuery } from '@tanstack/react-query';

export default function useGetAllAssistants() {
  return useQuery({
    queryKey: ['assistants'],
    queryFn: async () => {
      try {
        const { data: assistantsData } = await assistants.getAll();

        return assistantsData;
      } catch (error) {
        console.error(error);
      }
    },
  });
}
