import { login } from '@lib/auth/recaptcha';
import { setSession } from '@lib/jwt';
import axios, { AxiosRequestConfig, isAxiosError } from 'axios';

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_KNOWZ_FLOWS_URL || '',
});

interface RetryQueueItem {
  resolve: (value: AxiosRequestConfig | void) => void;
  reject: (error: Error) => void;
  config: AxiosRequestConfig;
}

const refreshAndRetryQueue: RetryQueueItem[] = [];

let isTrying = false;

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.error(error, '🚫🚫🚫🚫🚫🚫🚫🚫');
    const originalRequest: AxiosRequestConfig = error.config;

    if (isAxiosError(error) && error.response?.status === 401) {
      if (!isTrying) {
        isTrying = true;
        try {
          const response = await login();

          const webFingerToken = response.data.token;

          setSession(webFingerToken);

          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            config.headers!['Authorization'] = `Bearer ${webFingerToken}`;
            axiosInstance.request(config).then(resolve).catch(reject);
          });

          refreshAndRetryQueue.length = 0;
          originalRequest.headers![
            'Authorization'
          ] = `Bearer ${webFingerToken}`;

          return axiosInstance(originalRequest);
        } catch (error) {
          console.error(error);
          return Promise.reject(error);
        } finally {
          isTrying = false;
        }
      }

      return new Promise<void>((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
      });
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
