import SinglePhotosColBlock from '@components/pages/search/components/shared-blocks/single-photos-col';
import { WSResponseType } from '@state/middleware/types';
import { RootType } from '@state/store';
import { useSelector } from 'react-redux';

export default function StreamingSinglePhotosCol() {
  const searchResult = useSelector<RootType, WSResponseType>(
    (state) => state.websocket.WSResponse,
  );

  const photosData = searchResult.searchResult.layout?.find(
    (block) => block?.type === 'photos',
  );
  const infoData = searchResult.searchResult.layout?.find(
    (block) => block?.type === 'info',
  );
  const answerData = searchResult.searchResult.layout?.find(
    (block) => block?.type === 'answer',
  );

  return (
    photosData &&
    photosData.photos.length > 0 && (
      <SinglePhotosColBlock
        isThereAnswer={answerData == null ? false : true}
        isThereInfo={infoData == null ? false : true}
        photosData={photosData}
      />
    )
  );
}
