import EllipsisTypography from '@components/ellipsis-typography';
import { useTranslation } from '@desygner/ui-common-translation';
import useAuth from '@hooks/useAuth';
import {
  default as CONFIG,
  default as CONFIG_TOOLBAR,
} from '@layouts/main/sections/toolbar/config';
import CreditUsage from '@layouts/main/sections/toolbar/expanded/sections/credit-usage';
import {
  StyledExpandedToolbar,
  StyledListItem,
} from '@layouts/main/sections/toolbar/expanded/styles';
import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';
import { StyledListStack } from '@layouts/main/sections/toolbar/mini/desktop/styles';
import Socials from '@layouts/main/sections/toolbar/socials';
import { StyledNavLink } from '@layouts/main/sections/toolbar/styles';
import { convertToRem } from '@lib/fonts';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import paths from '@router/paths';

const FULL_YEAR = new Date().getFullYear();

const AVATAR_SIZE = 32;
const FULL_NAME_SKELETON_WIDTH = 120;
const FULL_NAME_SKELETON_HEIGHT = 30;

export default function ExpandedToolbar({ children }: React.PropsWithChildren) {
  const { t } = useTranslation();
  const {
    toolbarExpansionStatus,
    handleHideExpandedButton,
    handleDisplayExpandedButton,
  } = useToolbar();
  const { me, isUserAuthenticated } = useAuth();

  return (
    toolbarExpansionStatus === 'on' && (
      <Box
        onMouseEnter={handleDisplayExpandedButton}
        onMouseLeave={handleHideExpandedButton}
        sx={{
          pr: convertToRem(CONFIG.PATH_TO_SHOW_COLLAPSING_BUTTON),
        }}
      >
        <StyledExpandedToolbar disableGutters>
          <Stack
            sx={{
              height: '100%',
              justifyContent: 'space-between',
              overflow: 'auto',
              width: '100%',
            }}
          >
            <StyledListStack
              disablePadding
              component={Stack}
              direction="column"
            >
              <ListItem disablePadding disableGutters>
                <ListItemButton disabled>
                  <Stack direction="row" gap={2} sx={{ alignItems: 'center' }}>
                    <Avatar
                      alt={`${me?.first_name} ${me?.last_name}`}
                      sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}
                    />
                    {isUserAuthenticated ? (
                      <EllipsisTypography lineClamp={1} variant="body2">
                        {me?.first_name} {me?.last_name}
                      </EllipsisTypography>
                    ) : (
                      <Skeleton
                        width={FULL_NAME_SKELETON_WIDTH}
                        height={FULL_NAME_SKELETON_HEIGHT}
                      />
                    )}
                  </Stack>
                </ListItemButton>
              </ListItem>
              {CONFIG_TOOLBAR.TOOLBAR_ITEMS.filter(
                ({ isAlpha }) => me?.alpha || !isAlpha,
              ).map(({ defaultLabel, icon, mappedLabel, url }, i) => (
                <StyledListItem
                  to={url}
                  key={i}
                  component={StyledNavLink}
                  disablePadding
                  disableGutters
                >
                  <ListItemButton>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText
                      primary={t(mappedLabel, { defaultValue: defaultLabel })}
                      primaryTypographyProps={{
                        variant: 'body2',
                      }}
                    />
                  </ListItemButton>
                </StyledListItem>
              ))}
            </StyledListStack>
            <Stack sx={{ width: '100%' }}>
              <Divider />
              <ListItem disablePadding disableGutters>
                <ListItemButton href={paths.tos.pathname} target="_blank">
                  <ListItemText
                    primary={t('page.home.footer.tos', {
                      defaultValue: 'Terms of Service',
                    })}
                    primaryTypographyProps={{
                      variant: 'body2',
                    }}
                  />
                </ListItemButton>
              </ListItem>
              <CreditUsage />
              <Typography variant="caption" sx={{ py: 2, textAlign: 'center' }}>
                {t('page.home.footer.copyright', {
                  defaultValue: 'Copyright',
                })}
                &nbsp;© {FULL_YEAR} KnowZ
              </Typography>
              <Stack sx={{ justifyContent: 'center' }}>
                <Socials />
              </Stack>
            </Stack>
          </Stack>
          {children}
        </StyledExpandedToolbar>
      </Box>
    )
  );
}
